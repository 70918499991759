<div *ngIf="type =='prescription' || type =='gallery'" style="width: 100%;">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutWrap="wrap" fxLayoutAlign="start">
        <div fxFlex fxHide.lt-md  fxLayout="column" class="pr-1">
            <h4>Select files</h4>
            <div class="file-type" fxFlex.lt-md="50" ng2FileDrop (fileOver)="fileOverBase($event)"
                [uploader]="uploader">Base drop zone
            </div>
            <div class="file-type" fxFlex.lt-md="50" ng2FileDrop (fileOver)="fileOverAnother($event)"
                [uploader]="uploader">
                Another drop zone
            </div>
         
           <input [autocomplete]="'off'"hidden #file type="file" name="files" ng2FileSelect [uploader]="uploader" multiple />
        </div>
        <div fxFlex.gt-sm="80" fxFlex.lt-md="100">
            <div class="pad-wrap">
                <h4 fxLayout="row">
                    <span fxFlex>Upload queue</span>
                    <span>Queue length: {{ uploader?.queue?.length }}</span>
                </h4>


                <div class="uploadTable">
                    <div class="titleHead" fxLayout="row">
                        <div class="head" fxFlex="41" >
                            <span>Filename</span>
                        </div>
                        <div class="head" fxFlex >
                            <span>Size</span>
                        </div>
                        <!-- <div class="head" fxFlex>
                            <span>Progress</span>
                        </div> -->
                        <!-- <div class="head" fxFlex="20" >
                            <span>Status</span>
                        </div> -->
                        <div class="head" fxFlex>
                            <span>Action</span>
                        </div>
                    </div>
                    <div class="titleHead1" *ngFor="let row of uploader.queue let i=index" fxLayout="row">
                        <div class="head1" fxLayoutAlign="start center" fxFlex="41" >
                            <span style=" word-break: break-all"> {{ row?.file?.name | slice:0:30 }}</span>
                        </div>
                        <div class="head1" fxLayoutAlign="start center" fxFlex >
                            <span>{{ row?.file?.size/1024/1024 | number:'.2' }} MB</span>
                        </div>
                        <!-- <div class="head1" fxLayoutAlign="start center" fxFlex>
                            <mat-progress-bar mode="determinate" value="{{row.progress}}"></mat-progress-bar>
                        </div> -->
                        <div class="head1" fxLayoutAlign="start center" fxFlex="18" >
                            <span *ngIf="uploadmsg=='File Uploaded Successfully..'">
                                <mat-icon>check</mat-icon>
                            </span>
                            <span *ngIf="row.isCancel">
                                <mat-icon>cancel</mat-icon>>
                            </span>
                            <span *ngIf="row.isError">
                                <mat-icon>delete</mat-icon>>
                            </span>
                        </div>
                        <div class="head1" fxLayoutAlign="center center" fxFlex="10" fxFlex.lt-md="25">
                            <!-- <button mat-icon-button type="button" (click)="row.cancel()" [disabled]="!row.isUploading">
                <mat-icon>close</mat-icon>
              </button> -->
                            <button color="warn" mat-icon-button type="button" (click)="row.remove()">
                                <mat-icon>remove_circle_outline
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="mrgn-b-md">
                    <p>Queue progress:</p>
                    <mat-progress-bar mode="determinate" value="{{uploader.progress}}">
                    </mat-progress-bar>
                </div>
                <div fxLayout="row" style="padding-bottom: 10px;" fxLayoutGap="10px" fxLayout.lt-md="column"
                    fxLayoutAlign.gt-md="start center">
                    <button   type="button" mat-raised-button (click)="file.click()">
                        <mat-icon class="material-icons-outlined">
                            attach_file
                        </mat-icon>
                        Choose File
                    </button>
                    <button *ngIf="uploader?.queue?.length>0 " fxFlex.lt-md [disabled]="text !='Upload all'" (click)="upload()" type="button"
                        mat-raised-button>
                        {{text}}
                        <mat-icon *ngIf="text !='Upload all' ">
                            <mat-spinner class="primary" diameter="20">
                            </mat-spinner>
                        </mat-icon>
                    </button>
                    <!-- <button fxFlex.lt-md mat-raised-button type="button" (click)="uploader.cancelAll()"
                        [disabled]="!uploader.isUploading">Cancel all</button> -->
                    <button fxFlex color="warn" mat-raised-button type="button" (click)="uploader.clearQueue()"
                        [disabled]="!uploader.queue.length">Remove all</button>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="type =='prescription'" fxLayout="row"  fxLayout.lt-md="column" fxLayoutWrap="wrap"
    >
    <img class="img30" style="width: 100%;" src="assets/images/prec.jpeg" alt="prescription">
</div>
</div>

<div fxLayout="row" *ngIf="type!='prescription' && type!='gallery'" fxLayoutAlign="start center" fxLayoutGap="5px">

   <input [autocomplete]="'off'"type="file" #myInput ng2FileSelect [uploader]="uploader">
    <div fxLayout="row" class="padding-10" fxLayoutAlign="start center" fxLayoutGap="5px">

        <ng-container *ngIf="uploader?.queue?.length>0">
            <button fxFlex.lt-md [disabled]="text !='Upload all'" (click)="upload()" type="button" mat-raised-button>
                {{text}}
                <mat-icon *ngIf="text !='Upload all' ">
                    <mat-spinner class="primary" diameter="20">
                    </mat-spinner>
                </mat-icon>
            </button>
        </ng-container>

    </div>


</div>
