<!-- <div class="cardinfo-card-number">
  <label class="cardinfo-label" for="card-number">Numero de tarjeta</label>
  <div class='input-wrapper' id="card-number"></div>
</div>
<div class="expiry-date">
  <div class="cardinfo-exp-date">
	<label class="cardinfo-label" for="expiration-date">Mes Caducidad (MM)</label>
	<div class='input-wrapper' id="expiration-month"></div>
  </div>
<div class="cardinfo-exp-date2">
	<label class="cardinfo-label" for="expiration-date2">Año Caducidad (AA)</label>
	<div class='input-wrapper' id="expiration-year"></div>
</div>
<div class="cardinfo-cvv">
	<label class="cardinfo-label" for="cvv">CVV</label>
	<div class='input-wrapper' id="cvv"></div>
</div>
</div>
<input type="hidden" id="token" >
<input type="hidden" id="errorCode" > -->

<div id="card-form">

</div>
<input type="hidden" id="token" >
<input type="hidden" id="errorCode" >
