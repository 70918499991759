import { Component } from '@angular/core';

@Component({
  selector: 'calsoft-header38',
  templateUrl: './header38.component.html',
  styleUrls: ['./header38.component.scss']
})
export class Header38Component {
  searchQuery: string = '';

  onSearch() {
    if (this.searchQuery) {
      // Implement your search functionality here
      console.log('Searching for:', this.searchQuery);
    }
  }
}