import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-side-bar-menu-child',
  templateUrl: './sidemenu-child.component.html',
  styleUrls: ['./sidemenu-child.component.scss']
})
export class SidemenuChildComponent {

  @Input() child: any;
  @Input() categoryList:any;
  @Input() index:any;
  constructor(
    private commonService:CommonService

  ) { }

  ngOnInit(): void {

  }

  productList(menu,length){
    if(length ==0){
      this.commonService.selectedCategory(menu.categoryName,menu.categoryId,menu);
     // this.commonService.goToCategory(item.categoryName,Constant.DEFAULT_PAGE_SIZE);

    }else{

    }

  }
  toggleSection(i,object) {
   if(object.subcategories.length >0){
     object.open = !object.open;
   }

this.toggleNested(object.subcategories)
 }
  

  toggleNested(items) {
    items.forEach(item => {
      if (item.subcategories && item.subcategories.length > 0) {
        item.open = false // Toggle the state of the current item
        this.toggleNested(item.subcategories); // Recursively toggle nested items
      }
    });
  }

}





