import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constant } from 'src/app/constants/constant';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent {

 paymentHandler: any = null;

 
 
 status:boolean=false;

 public stripeAPIKey: any;
  
 email: string;

  @Input() finalAmount: any;

  @Input() data: any;

  @Input() order: any;

  @Input() configData: any;

  @Input() partialAmountEnabled : any;

  @Input() contact: any;

  @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private configService :ConfigServiceService,
    private commonService:CommonService,
    private userService: UserService,
    private snackBar: MatSnackBar,
  ) {}

 ngOnInit() {
   this.getConfigData();
  }

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.commonService.setCurrencyValue(data['currencyBase'])
          this.stripeAPIKey = data['stripeKey'];
          this.status=true;
          this.invokeStripe();

          }, error => {
            this.stripeAPIKey = 'pk_test_51N0LspSHOnsu4Vo5LqXCUvWxTvC74Y2QGnbHAdpTh47tpOayHliBKVKWV1UOKNP0QJBXiK7s555T7f4I4PUVnxbl00VE4ysYmN'
            this.status=true;
          }
          );
}



  makePayment() {
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: this.stripeAPIKey,
      locale: 'auto',

     


      token: (stripeToken: any) => {

  
        let authourized = this.userService.getAuthenticatedUser();
        let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
        let baseurl="/api/payment/ig/stripe/payment-details"
        if(guestLoginEnabled=="0"||authourized){
           baseurl="/api/payment/stripe/payment-details"
          
        }
        let object={

          amount:Math.round(this.finalAmount)* 100,
          hash:stripeToken.id
        }
        let  url = environment.serverAPI3 + baseurl;
        // Your code for handling the token event
        return this.http.post<any>(`${url}`, object)
        .subscribe(
          res => {
            
        if(res['data']['status']=='succeeded'){
          this.emitResponse(res['data']);
        }else{
          this.snackBar.open(res['status_message'], '×',
           { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
        }
        
      })

       
      },
      closed: () => {
        // Your code for handling the closed event
      
      },
    });
  
    paymentHandler.open({
      name: Constant.siteName,
      description: 'Payment Transaction',
      amount: Math.round(this.finalAmount)*100,
    });
  }
  


    invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
  
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: this.stripeAPIKey,
          locale: 'auto',
          token: function (stripeToken: any) {
           
          },
        });
      };
  
      window.document.body.appendChild(script);
    }
  }

  additionData: string;
  paymentObject: any;

  emitResponse(response){
    let paymentInformation = {}

    if(this.partialAmountEnabled==1){
      paymentInformation = {
        Partial_Paid_Amount:this.finalAmount,
        method: 'Stripe',
        type:response.type,
        client_ip:response.client_ip,
        payment_id: response.id,
        email:response.email,
      }
    }else{
      paymentInformation = {
        method: 'Stripe',
        type:response.type,
        client_ip:response.client_ip,
        payment_id: response.id,
        email:response.email,
  
      }
    }
   

    this.additionData = JSON.stringify(paymentInformation)

    this.paymentObject = {
      salesPaymentTransaction: [{
        txn_id: response.id,
        txn_type: "customer",
        isClose: true
      }],
      salesFlatOrderPayment: {
        method: 'Stripe',
        additionalInformation: this.additionData

      },
      paypalPaymentStatus: "Successful"

    }

     this.paymentStatus.emit(this.paymentObject);
  }
}