import { Component } from '@angular/core';
import { PaymentService } from 'src/app/services/payment-service';
@Component({
  selector: 'calsoft-phonepe',
  templateUrl: './phonepe.component.html',
  styleUrls: ['./phonepe.component.scss']
})
export class PhonepeComponent {


  constructor(private paymentService:PaymentService){


  }

  ngOnInit(): void {



   
  }
 int(){

  
}



pay(){

  // const phonePeSDK = new PhonePeSDK({
  //   merchantId: 'WHOLEMONKEYUAT',
  //   merchantKey: '7887c825-8ad1-49e5-b49a-5133a2e8cec2',
  //   returnUrl: 'localhost:4200',
  //   cancelUrl: 'localhost:4200',
  // });
   

  //   const paymentRequest = {
  //     amount: 100.00,
  //     currency: 'INR',
  //     orderId: '12345',
  //     customerName: 'John Doe'
  //   };

   let object= {
      "merchantId": "WHOLEMONKEYUAT",
      "merchantTransactionId": "WHOLEMONKEYUAT01",
      "merchantUserId": "WHOLEMONKEYUAT",
      "amount": 100.00,
      "redirectUrl": "http://localhost:4200",
      "redirectMode": "POST",
      "callbackUrl": "hhttp://localhost:4200",
      "mobileNumber": "9999999999",
      "paymentInstrument": {
        "type": "PAY_PAGE"
      }
    }
    

    let url='https://api-preprod.phonepe.com/apis/merchant-simulator/pg/v1/pay';
   let response= this.paymentService.phonePePayment(url,object)
   console.log(response);
   
  }


}
