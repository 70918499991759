<div class="row" fxFlex>
    <div  class="d-none d-xl-block">
        <nav class="side-nav">
            <div class="sidenav-title primary" style="cursor: pointer;" (mouseenter)="changeMenu()" >
                <div style="display: flex;">
                    <h6>Browse Categories</h6>
                    <mat-icon (click)="change_Menu()">{{maticon}}</mat-icon>
                </div>
            </div>
            <div *ngIf="menu_show" (mouseleave)="change_Menu()">
            <ul class="menu-vertical sf-arrows" *ngFor="let menu of categoryArray; " #categoryElement >
                <li class="megamenu-container" (mouseover)="onHover(menu.children ? true : false)">
                    <a style="cursor: pointer;" (click)="goToCategory(menu)" [ngClass]="{'sf-with-ul': menu.subcategories.length > 0}">
                        <span *ngIf="menu.subcategories.length > 0">{{menu.categoryName| characterPipe}}</span>
                        <span (click)="goToCategory(menu)" *ngIf="menu.subcategories.length == 0">{{menu.categoryName | characterPipe}}</span>
                    </a>

                    <div [ngStyle]="calculateMegamenuPosition(categoryElement)"  class="megamenu megamenu-sm" *ngIf="menu.subcategories.length > 0">                       
                            <div class="row" >

                                  <div class="col-xl-4" [style.width.%]="'15'" *ngFor="let submenu of menu.subcategories; let i = index">
                                  <div *ngIf="submenu.subcategories.length > 0">
                                    <div class="menu-title" (click)="goToCategory(submenu)">{{submenu.categoryName | characterPipe}}</div>

                                    <calsoft-hover-menu-sub [category]="submenu.subcategories"></calsoft-hover-menu-sub>
                                   
                                  </div>
                                  <div *ngIf="submenu.subcategories.length === 0">
                                    <div class="menu-title1" (click)="goToCategory(submenu)">{{submenu.categoryName | characterPipe}}</div>
                                  </div>
                                </div>

                            </div>
                            
                    </div>  
                      
                </li>
            </ul>
            </div>
        </nav>
    </div>
</div>
