import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReviewRatingService } from 'src/app/services/review-rating.service';

@Component({
  selector: 'calsoft-Rating',
  templateUrl: './Rating.component.html',
  styleUrls: ['./Rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input() rate: any;
  @Input() id: any;
  @Input() ratingsCount: number;
  @Input() ratingsValue: number;
  @Input() direction: string;
  @Input() type: string;
  @Output() onRatingChange: EventEmitter<any> = new EventEmitter<any>();
  avg: number;
  stars: Array<string>;
  ratingMode: string;
  rating: Object;

  constructor(
    private ratingService: ReviewRatingService
  ) { }

  ngDoCheck() {

    this.calculateAvgValue();

  }

  ngOnInit(): void {
    // if(this.type =='checkout'){
    //   this.getRating();
    // }
  }

  getRating() {
    this.ratingService.getRatings().subscribe(

      res => {
        this.rating = res['content'];
        this.rating['star'] = this.stars;

      }

    )
  }

  rates(i, j) {
    this.ratingsCount = i + 1;
    let rating = {
      count: this.ratingsCount,
      ratingPercent: this.ratingsCount * 20,
    }

    //  this.calculateAvgValue();
    this.onRatingChange.emit(rating);
  }

  calculateAvgValue() {
    this.avg = this.ratingsValue / this.ratingsCount;
    if(this.ratingsCount > 0 && this.ratingsCount < 1){
        this.stars = ['star_half', 'star_border', 'star_border', 'star_border', 'star_border'];
      }
   else if( this.ratingsCount == 1){
        this.stars = ['star', 'star_border', 'star_border', 'star_border', 'star_border'];
        this.ratingMode = "Very Bad"
      }
    else if( this.ratingsCount > 2 && this.ratingsCount < 2){
        this.stars = ['star', 'star_half', 'star_border', 'star_border', 'star_border'];
      }
      else if( this.ratingsCount == 2){
        this.stars = ['star', 'star', 'star_border', 'star_border', 'star_border'];
        this.ratingMode = "Bad"
      }
      else if( this.ratingsCount > 3 && this.ratingsCount < 3){
        this.stars = ['star', 'star', 'star_half', 'star_border', 'star_border'];
      }
      else if( this.ratingsCount == 3){
        this.stars = ['star', 'star', 'star', 'star_border', 'star_border'];
        this.ratingMode = "Good"
      }
      else if( this.ratingsCount > 4 && this.ratingsCount < 4){
        this.stars = ['star', 'star', 'star', 'star_half', 'star_border'];
      }
      else if( this.ratingsCount == 4){
        this.stars = ['star', 'star', 'star', 'star', 'star_border'];
        this.ratingMode = "Very Good"
      }
      else if( this.ratingsCount > 5 && this.ratingsCount < 5){
        this.stars = ['star', 'star', 'star', 'star', 'star_half'];
      }
      else if( this.ratingsCount >= 5){
        this.stars = ['star', 'star', 'star', 'star', 'star'];
        this.ratingMode = "Excellent"
      }
      else{
        this.stars = ['star_border', 'star_border', 'star_border', 'star_border', 'star_border'];
      }

  }

}
