<div [formGroup]="forgotPasswordForm">

    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-sm="10px">
        <div fxLayout.lt-sm="column" fxFlex.gt-sm="50" fxFlex.lt-sm>
            <mat-form-field appearance="outline">
                <mat-label>Password
                    <span *ngIf="isRequired(field.name)" class="required-symbol">*</span>
                </mat-label>
                <input trim="blur" name="new-label" [autocomplete]="'off'" matInput formControlName="password"
                    type="password" required #passwordField>
                <button mat-icon-button
                    (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                    tabindex="-1" matSuffix>
                    <mat-icon *ngIf="passwordField.type === 'password'">visibility</mat-icon>
                    <mat-icon *ngIf="passwordField.type === 'text'">visibility_off</mat-icon>
                </button>
                <mat-error *ngIf="forgotPasswordForm.controls.password.errors?.required||
                group.controls[field.name].errors?.required">
                    Please fill out this field.
                </mat-error>
                <mat-error
                    *ngIf="forgotPasswordForm.controls.password.hasError('minlength')&&passwordValidationRequired==0">
                    Should Contain atleast {{passwordMinimumCharacters}} Characters.!
                </mat-error>


            </mat-form-field>
            <div fxLayout="column" *ngIf="passwordValidationRequired == 1">
                <h6 fxLayout="row"
                    [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons matIcon">{{
                        forgotPasswordForm.controls['password'].hasError('required') ||
                        forgotPasswordForm.controls['password'].hasError('minlength') ? 'cancel' :
                        'check_circle' }}</i>
                    &nbsp; Should Contain atleast {{passwordMinimumCharacters}} Characters.!
                </h6>
                <h6 fxLayout="row"
                    [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons matIcon">{{
                        forgotPasswordForm.controls['password'].hasError('required') ||
                        forgotPasswordForm.controls['password'].hasError('hasNumber') ? 'cancel' :
                        'check_circle' }}</i>
                    &nbsp; Contain at least 1 number!
                </h6>

                <h6
                    [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons matIcon">{{
                        forgotPasswordForm.controls['password'].hasError('required') ||
                        forgotPasswordForm.controls['password'].hasError('hasCapitalCase') ?
                        'cancel' :
                        'check_circle' }}</i>
                    Contain at least 1 in Capital Case!
                </h6>
                <h6
                    [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                    <i class="material-icons matIcon">{{
                        forgotPasswordForm.controls['password'].hasError('required') ||
                        forgotPasswordForm.controls['password'].hasError('hasSmallCase') ? 'cancel'
                        :
                        'check_circle' }}</i>
                    Contain at least 1 Letter in Small Case!
                </h6>
                <h6
                    [ngClass]="forgotPasswordForm.controls['password'].hasError('required') || forgotPasswordForm.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                    <i class="material-icons matIcon">{{
                        forgotPasswordForm.controls['password'].hasError('required') ||
                        forgotPasswordForm.controls['password'].hasError('hasSpecialCharacters') ?
                        'cancel' :
                        'check_circle' }}</i>
                    Contain at least 1 Special Character!
                </h6>

            </div>
        </div>
        <div fxLayout.lt-sm="column" fxFlex.gt-sm="50" fxFlex.lt-sm>
            <mat-form-field appearance="outline">
                <mat-label>Confirm Password </mat-label>
                <input (change)="setValueOnFormValid()" matInput formControlName="confirmPassword" type="password"
                    required #passwordFields>
                <button mat-icon-button
                    (click)="passwordFields.type === 'password' ? passwordFields.type = 'text' : passwordFields.type = 'password'"
                    tabindex="-1" matSuffix>
                    <mat-icon *ngIf="passwordFields.type === 'password'">visibility</mat-icon>
                    <mat-icon *ngIf="passwordFields.type === 'text'">visibility_off</mat-icon>
                </button>
                <mat-error *ngIf="forgotPasswordForm.controls.confirmPassword.hasError('required') ">
                    Please fill out this field.</mat-error>

                <mat-error *ngIf="forgotPasswordForm.controls.confirmPassword.hasError('mustMatch')">
                    Passwords
                    do not match</mat-error>
            </mat-form-field>
        </div>
    </div>
</div>