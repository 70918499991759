import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Constant } from 'src/app/constants/constant';

import { CcAvenueService } from 'src/app/services/cc-avenue.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { EcryptServiceService } from 'src/app/services/ecrypt-service.service';
import { PhonePeService } from 'src/app/services/phone-pe.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-phone-pe',
  templateUrl: './phone-pe.component.html',
  styleUrls: ['./phone-pe.component.scss']
})
export class PhonePeComponent  implements OnInit,OnChanges  {

    @Input() data: any;
  
    @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>();
    
    @Input() order: any;
  
  
    @Input() finalAmount: any;
  
    @Input() partialAmountEnabled : any;
    @ViewChild('form', { static: false }) formRef: ElementRef;
    @Input() contact: any;
    paypalLoad: boolean=false;
    srcUrl: string;
    txnId: any;
    ccAvenueUrl: any;
    additionData: string;
    paymentObject: { salesPaymentTransaction: { txn_id: any; txn_type: string; isClose: boolean; }[]; salesFlatOrderPayment: { method: string; additionalInformation: string; }; paypalPaymentStatus: string; };
    email: any;
  
    constructor(public phonePeService:PhonePeService,
      private configService :ConfigServiceService,
      private commonService:CommonService,
      private http: HttpClient,
      private snackBar: MatSnackBar,
      private ecryptService: EcryptServiceService,
      private sanitizer: DomSanitizer) { }
  
    encRequest: any;
    accessCode: String;
    merchantId: any;
    Enckey:any;
  
   @ViewChild('form', { static: true }) form: ElementRef;
    encRequestRes : any;
    order_no : any = 'qaz234567';
    testAmount : any = '10';
    selectedAddress : any = {
      name : 'testing',
      address : 'test address',
      city : 'test city',
      pincode : '23456',
      state : 'state test',
      phone : '1234567890'
    }
    ngOnInit(){
    
      delete this.order["customerGender"]

      console.log(this.order);
      
   
      this.getConfigData();
    let value=  this.email=localStorage.getItem('authenticaterUserEmail');
       if(value!=null){
        this.email=localStorage.getItem('authenticaterUserEmail');
       }else{
        this.order.customerId=1;
        this.email=this.data['lastName']
        this.order['cartId']=localStorage.getItem(Constant['CART_ID']);
       }
  
       
    }
  
    getConfigData() {
      this.configService.getConfigData()
        .subscribe(
          data => {
            this.commonService.setCurrencyValue(data['currencyBase'])
            this.ccAvenueUrl=data['ccAvenueUrl'];
            this.accessCode= data['ccAvenueAccessCode'];
            this.merchantId = data['ccAvenueMerchantId'];
            this.Enckey  =data['ccAvenueEncKey'];
            
            this.paypalLoad=false;
  
            }, error => {
              this.accessCode='AVKE93KA88AK07EKKA';
              this.Enckey='56B104EC14BE9C6A00D102C0B068C10B';
              this.merchantId='merchant_id';
              this.paypalLoad=false;
            }
            );
  }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    
    delete this.order["customerGender"]
   
    this.getConfigData();
  let value=  this.email=localStorage.getItem('authenticaterUserEmail');
     if(value!=null){
      this.email=localStorage.getItem('authenticaterUserEmail');
     }else{
      this.order.customerId=1;
      this.email=this.data['lastName']
      this.order['cartId']=localStorage.getItem(Constant['CART_ID']);
     }
  
  }
  @ViewChild('encRequestInput', { static: false }) encRequestInputRef: ElementRef;
  @ViewChild('accessCodeInput', { static: false }) accessCodeInputRef: ElementRef;
  
    checkout(){
  
      let value=window.location.origin;
      let url=value +"/pay";

   let orderData=this.ecryptService.encrypt(this.order)
      let request = {
        // currency:"INR",
        // amount:this.finalAmount,
        // redirect_url:url,
        // cancel_url:url,
        // integration_type:'iframe_normal',
        // language:'en',
        // name:this.data.firstName,
        // address:this.data.street,
        // zip:this.data.postCode,
        // city:this.data.city,
        // state:this.data.state,
        // country:this.data.region,
        // phone:this.data.telephone,
        // email:this.email,
        // order_cons:orderData
        phone:this.data.telephone,
        amount:this.finalAmount * 100,
        order_cons:orderData,
        redirectUrl:url,
        callbackUrl:url,
        
      }
  
     
      this.phonePeService.post(request).subscribe(
        (response) => {
  
          let redirect_url=response['data']
          ['instrumentResponse']['redirectInfo']['url'];

          console.log(response);
          
          window.location.href=redirect_url;
  
        },
        error => {
        }
      )
    }
  
  
    closePopUp(value){
  
      let  url = environment.serverAPI3 + "/api/payment/ig/ccAvenue/status";
      return this.http.post<any>(`${url}`, value).subscribe(
    
        response => {
  
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
  
        
            if(response['data']==null){
              this.snackBar.open('Payment failed. Please try again.', '×',
              { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
              
            }else{
              let response1=JSON.parse(response.data);
              if(response1['order_status']=="Success"){
                this.emitResponse(response1)
                
              }
            }
      
          
          }else{
            this.snackBar.open('Payment failed. Please try again.', '×',
            { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
      
    
      
      }, error1 => {
  
        })
    }
  
  
    emitResponse(response){
      let paymentInformation = {}
  
      if(this.partialAmountEnabled==1){
        paymentInformation = {
          tracking_id: response['tracking_id'],
          bank_ref_no: response['bank_ref_no'],
          payment_mode:response['payment_mode'],
          method: 'CCAvenue',
        }
      }else{
        paymentInformation = {
          tracking_id: response['tracking_id'],
          bank_ref_no: response['bank_ref_no'],
          payment_mode:response['payment_mode'],
          method: 'CCAvenue',
    
        }
      }
     
  
      this.additionData = JSON.stringify(paymentInformation)
  
      this.paymentObject = {
        salesPaymentTransaction: [{
          txn_id: response['tracking_id'],
          txn_type: "customer",
          isClose: true
        }],
        salesFlatOrderPayment: {
          method: 'CCAvenue',
          additionalInformation: this.additionData
  
        },
        paypalPaymentStatus: "Successful"
  
      }
  
       this.paymentStatus.emit(this.paymentObject);
    }
  
  
  
  }
  