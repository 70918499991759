<div class="dropdown">


    <div fxLayout="row" fxLayoutAlign="center center">
      <button  class="dropdown-toggle" mat-raised-button color="primary" [style.background]="isOpen ? '#AD8B3A' : '#AD8B3A'" (click)="toggleMenu()" class="btncss" >
        {{ isOpen ? 'CLOSE' : 'BOOK A STAY' }}
        <mat-icon *ngIf="isOpen" style="padding-top: 5px;font-size: 18px;">close</mat-icon>
      </button>
    </div>
    <div class="dropdown-menu padding-10" style="width: 400px;" [@dropdownAnimation]="isOpen ? 'open' : 'closed'">
      
      <div fxLayout="column">
        <mat-form-field floatLabel="never" fxFlex style="font-size: 16px;">
          <input matInput placeholder="Find a Hotel or Destination">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center">
          <mat-form-field fxFlex="100" style="font-size: 16px;">
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Check-in Date" [(ngModel)]="startDate">
              <input matEndDate placeholder="Check-out Date" [(ngModel)]="endDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
    

          <mat-accordion> 
            <mat-expansion-panel > 
              <mat-expansion-panel-header> 
                <mat-panel-description> 

                    <div fxLayout="row" fxFlex="100" >         
                        <mat-form-field fxFlex="100" style="font-size: 16px;">                       
                          <input [(ngModel)]="guestCount" matInput style="font-size: 16px;">
                          <mat-icon  fxLayoutAlign="end end" matPrefix style="font-size: 16px;">person</mat-icon>
                        </mat-form-field>
                        <!-- <mat-icon  (click)="toggleMenu1()" fxLayoutAlign="end end" matPrefix>arrow_downward</mat-icon> -->
                    </div>
 
                </mat-panel-description> 
              </mat-expansion-panel-header> 
            
              <div fxLayout="column" fxLayoutGap="10px">
                <div *ngFor="let room of bookingInfo; let i = index">
                  <button mat-icon-button (click)="removeRoom(i)">
                    <mat-icon>remove_circle</mat-icon>
                  </button>
                  <input type="text" [(ngModel)]="room.info" placeholder="Room {{i + 1}} - {{room.adults}} Adult" readonly style="border: none;">
                  <div fxLayout="row" fxLayoutAlign="start center" style="background-color: #F6F5F5;padding: 10px;">
      
                    <div fxFlex="50">

                      <span class="label" style="color: gray;">Adult</span>

                      <button mat-icon-button (click)="decrementAdult(room)">
                        <mat-icon>remove</mat-icon>
                      </button>
                                           
                      <input type="number" [(ngModel)]="room.adults" style="width: 20%;border: none;text-align: end;" readonly>
                      <button mat-icon-button (click)="incrementAdult(room)">
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>                
      
                    <div fxFlex="50">
                      <span class="label" style="color: gray;">Children</span>
                      <button mat-icon-button (click)="decrementChildren(room)">
                      <mat-icon>remove</mat-icon>
                    </button>
                    <input type="number" [(ngModel)]="room.children" style="width: 20%;border: none;text-align: end" readonly>
                    <button mat-icon-button (click)="incrementChildren(room)">
                      <mat-icon>add</mat-icon>
                    </button>
                    </div>
                  </div>
                  
                </div>
                <a mat-button (click)="addRoom()" class="morebtn" >ADD MORE ROOMS</a>
              </div>
                              
            </mat-expansion-panel>             
          </mat-accordion> 
      
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="50">
          <mat-form-field  style="font-size: 16px;margin-top: 10px;">
            <mat-label>Special Code</mat-label>
            <mat-select [(ngModel)]="specialcat">
              <mat-option value="0">None</mat-option>
              <mat-option value="1">Corporate Access Code</mat-option>
              <mat-option value="2">Travel Agency code</mat-option>
              <mat-option value="3">Business Connect code</mat-option>
              <mat-option value="4">Group code</mat-option>
            </mat-select>
          </mat-form-field>

                  <div style="display: flex;" *ngIf="specialcat == '1' || specialcat == '2'">
                    <input  matInput type="text" style="width: 60%;border-bottom: 1px solid gray;" >
                    <button mat-button style="background-color: black;color: white;">Add</button>
                  </div> 
        </div>
        <!-- <mat-expansion-panel  fxFlex="50">
          <mat-expansion-panel-header>
            <mat-panel-title>Special Code</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <mat-list>
                <mat-list-item  class="custom-list-item">
                  none
                </mat-list-item>
                <mat-list-item  class="custom-list-item">
                    Corporate Access Code
                  </mat-list-item>
            </mat-list>

          </mat-panel-description>
        </mat-expansion-panel> -->
      
        <!-- Check rates button -->
        <button fxFlex="50" [style.background]="isOpen ? '#AD8B3A' : '#AD8B3A'"  mat-raised-button color="primary">Check rates</button>
  
        </div>
      </div>
  </div>