<!-- <ng-container *ngIf="rate">
   <bar-rating [(rate)]="rate" max="5" readOnly=true></bar-rating>
</ng-container> -->

<div id="rating" *ngIf="type=='checkout'">
    <div class="padding-10" fxLayout="row" fxLayoutAlign.lt-sm="space-between center" fxLayoutGap.gt-sm="30px">
        <div fxFlex.gt-sm="20" fxLayoutAlign="start center">
            <span> Quality :</span>
        </div>
        <div fxFlex fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="ratings">
                <mat-icon [class.disableStar] = "id>0" class="pointer" *ngFor="let star of stars; let i=index;" (mouseover)="rates(i,j)">{{star}}
                </mat-icon>
            </div>
            <span class="ratings-count text-muted">{{ratingMode}}</span>
        </div>
    </div>
</div>