<mat-list *ngFor="let subChild of child.subcategories;  let i = index"  lines="none"
              >
              <div matRipple tappable>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-label fxFlex="80"  (click)="productList(subChild , subChild.subcategories.length)" 
                
                color="dark" class="itemLabel padding-tlr">
                  {{ subChild.categoryName }}
                </mat-label>
                <mat-icon  (click)="toggleSection(i,subChild)" matSuffix *ngIf="subChild.subcategories.length >0 && subChild.open !=true">arrow_right</mat-icon>
                <mat-icon (click)="toggleSection(i,subChild)" class="iconClr" *ngIf="subChild.open ==true">arrow_drop_down</mat-icon>
              </div>
            </div>
              <div *ngIf="subChild.subcategories && subChild.open" class="product-list" lines="none">
              <calsoft-side-bar-menu-child [categoryList]="subChild.subcategories" [index]="k" [child]="subChild"></calsoft-side-bar-menu-child>
              </div>
            </mat-list>