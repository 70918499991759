import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardNew {


  constructor(private auth: UserService,
    private router: Router,
    private dialog: MatDialog,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let basicAuthHeaderString = this.auth.getAuthenticatedToken();
    let username = this.auth.getAuthenticatedUser();

    let loginPage: any = localStorage.getItem('redirectToLoginPage');

    if (basicAuthHeaderString) {
      return true;
    } else if (loginPage == 1) {
      this.router.navigate(['/login']);
      return true;
    }
    else
      return true;
  }


}
