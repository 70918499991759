<mat-toolbar class="mobileheader">
    <mat-toolbar-row style="padding: 0px;" [style.margin]="mobileHeaderLogoGap" [style.height]="mobileHeaderHeight">
      <div class="padding-10" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center">
          <button (click)="searchLocation()" [style.color]="data.headerIconColour" class="responsive-toggle" mat-icon-button>
            <i class="material-icons">pin_drop</i>
            <b>Other</b>
          </button>
        </div>
        <button  (click)="SignIn('login')" *ngIf="!userLoggedIn" [style.color]="data.headerIconColour" class="responsive-toggle" mat-mini-fab>
          <mat-icon>person_outline</mat-icon>
        </button>
      </div>

        <calsoft-HeaderUserProfileDropdown [style.color]="data.headerIconColour" class="headerUser"
                *ngIf="userLoggedIn">
         </calsoft-HeaderUserProfileDropdown>

      <div fxFlex="3">

      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  