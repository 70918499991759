import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { CurrencyConversionService } from 'src/app/services/currency-conversion.service';
declare let paypal: any;
@Component({
  selector: 'app-pay-pal',
  templateUrl: './pay-pal.component.html',
  styleUrls: ['./pay-pal.component.scss']
})
export class PayPalComponent implements OnInit, AfterViewInit {

  @Input() finalAmount: any;
  currencyRate: any;
  exchangeRate: any;
  paymentObject: any;
  additionData: string;

  constructor(
    private currencyConversionService: CurrencyConversionService
  ) { }

  ngOnInit(): void {

    this.getCurrencyConversionRate();

  }

  getCurrencyConversionRate() {

    let currencyFrom = this.currencyConversionService.getCountryCode();
    if (currencyFrom == null) {
      currencyFrom = "INR";
    }
    if(currencyFrom!="USD"){
    this.currencyConversionService.getCurrencyRates(currencyFrom, 'USD').subscribe(
      res => {
        this.exchangeRate = res;
        this.currencyRate = this.finalAmount * this.exchangeRate;
      },
      error => {
      }
    )
    }else{
      this.currencyRate = this.finalAmount;
    }
  }


  ngAfterViewInit(): void {
    this.paypalInit();
  }
  @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>();

  addScript: boolean = false;
  paypalLoad: boolean = true;


  paypalConfig = {
    env: 'sandbox',
    client: {
      sandbox: 'AfcZwwOTd9WoVgccNcqNsi7aVLQhFmqDvJ6xNeg3jMGDnd_ZJdny_Ra6UrZgQbPwjDuiaVObz54Ch4lG',
      production: '<your-production-key here>'
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            { amount: { total: this.finalAmount.toFixed(2), currency: 'USD' } }
          ]
        }
      });
    },


    onApprove: (data, actions) => {
      return actions.order.capture().then((payment) => {

        let paymentInformation = {
          paymentCurrency: 'USD',
          exchangeRate: this.exchangeRate,
          paypalPayerId: payment['payer']['payer_id'],
          paypalPayerEmail: payment['payer']['email_address'],
          paypalPayerStatus: 'verified',
          paypalAddressStatus: 'unconfirmed',
          paypalProtectionEligibility: 'Ineligible',

        }

        this.additionData = JSON.stringify(paymentInformation)

        this.paymentObject = {
          salesPaymentTransaction: [{
            txn_id: payment.id,
            txn_type: payment.intent,
            isClose: true
          }],
          salesFlatOrderPayment: {
            method: 'paypal_standard',
            additionalInformation: this.additionData

          },
          paypalPaymentStatus: payment.status

        }

        this.paymentStatus.emit(this.paymentObject);


      })
    },

    onError: (err) => {
    },
  };
  paypalInit(): void {
    if (!this.addScript) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
        this.paypalLoad = false;
      })
    }
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }

}



