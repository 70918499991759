
import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { PixelConfiguration, PixelEventName, PixelEventProperties } from '../shared/pixel/pixel.models';
declare const ttq: any; // Declare ttq as a global variable


@Injectable({
  providedIn: 'root'
})
export class TiktokService {
  private doc: Document;
  private renderer: Renderer2
  enabled: boolean;
  pixelId: string;

  constructor(

    @Inject(DOCUMENT) private injectedDocument: any,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() private router: Router,
    private rendererFactory: RendererFactory2
  ) {

    // DOCUMENT cannot be injected directly as Document type, see https://github.com/angular/angular/issues/20351
    // It is therefore injected as any and then cast to Document
    this.doc = injectedDocument as Document;
    this.renderer = rendererFactory.createRenderer(null, null);

    if (router) {
      // Log page views after router navigation ends
      router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {

        if (this.isLoaded()) {
          this.track('PageView');
        }

      });
    }

  }

  /**
   * Initialize the Pixel tracking script
   * - Adds the script to page's head
   * - Tracks first page view
   */
  initialize(pixelId): void {
    if (this.isLoaded()) {
     // console.warn('Tried to initialize a Pixel instance while another is already active. Please call `remove()` before initializing a new instance.');
      return;
    }
    this.enabled = true;
    this.addPixelScript2(pixelId);
  }

  /** Remove the Pixel tracking script */
  remove(): void {
    this.removePixelScript();
    this.enabled = false;
  }

  /**
   * Track a Standard Event as predefined by Facebook
   *
   * See {@link https://developers.facebook.com/docs/facebook-pixel/reference Facebook Pixel docs - reference}
   * @param eventName The name of the event that is being tracked
   * @param properties Optional properties of the event
   */
   track(eventName: string, properties?: any): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
  
    if (!this.isLoaded()) {
      console.warn('Tried to track an event without initializing a Pixel instance. Call `initialize()` first.');
      return;
    }
  
    if (properties) {

     // fbq('track', eventName, properties);
     ttq.track(eventName, {
      "contents": properties.contents,
      "value": properties.value, // number. Value of the order or items sold. Example: 100.
      "currency": properties.currency // string. The 4217 currency code. Example: "USD".
    });
  ;
      
    } else {
      ttq('track', eventName);
    }
  }

  /**
   * Track a custom Event
   *
   * See {@link https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-conversions Facebook Pixel docs - custom conversions}
   * @param eventName The name of the event that is being tracked
   * @param properties Optional properties of the event
   */
  trackCustom(eventName: string, properties?: object): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this.isLoaded()) {
      console.warn('Tried to track an event without initializing a Pixel instance. Call `initialize()` first.');
      return;
    }

   if (properties) {
  ttq(eventName, properties);
} else {
  ttq(eventName);
}
  }




  /** Remove Facebook Pixel tracking script from the application */
  private removePixelScript(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const pixelElement = this.doc.getElementById('tiktok-pixel-script');
    if (pixelElement) {
      pixelElement.remove();
    }
  }

  /** Checks if the script element is present */
  private isLoaded(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const pixelElement = this.doc.getElementById('tiktok-pixel-script');
      return !!pixelElement;
    }
    return false;
  }

  private addPixelScript2(pixelId: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
  
    const pixelCode = `
      var ttq = window.ttq = window.ttq || [];
      ttq.methods = ["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"];
      ttq.setAndDefer = function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
      for (var i = 0; i < ttq.methods.length; i++) {
        ttq.setAndDefer(ttq, ttq.methods[i]);
      }
      ttq.instance = function(t) {
        var e = ttq._i[t] || [];
        for (var n = 0; n < ttq.methods.length; n++) {
          ttq.setAndDefer(e, ttq.methods[n]);
        }
        return e;
      };
      ttq.load = function(e,n) {
        var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i = ttq._i || {};
        ttq._i[e] = [];
        ttq._i[e]._u = i;
        ttq._t = ttq._t || {};
        ttq._t[e] = +new Date;
        ttq._o = ttq._o || {};
        ttq._o[e] = n || {};
        var o = document.createElement("script");
        o.type = "text/javascript";
        o.async = true;
        o.src = i + "?sdkid=" + e + "&lib=" + t;
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(o, a);
      };
      ttq.load('${pixelId}');
      ttq.page();
    `;
  
    const scriptElement = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElement, 'id', 'tiktok-pixel-script');
    this.renderer.setAttribute(scriptElement, 'type', 'text/javascript');
    this.renderer.setProperty(scriptElement, 'innerHTML', pixelCode);
    this.renderer.appendChild(this.doc.head, scriptElement);
    this.pixelId = pixelId;
  }
  
}

