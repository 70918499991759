
  <header>
    <nav>
      <div class="nav-left">
        <div class="nav-logo">
          <img src="https://saga.calsoftgroup.com/ecom-gateway/ecom-svc-core-1.0-SNAPSHOT/api/core/download?fileName=logo/logoBG.png">
        </div>
        <div class="location">
          <p class="top-text">Deliver to</p>
          <div class="location-icon">
            <i class="fa-solid fa-location-dot"></i>
            <p class="bottom-text">India</p>
          </div>
        </div>
      </div>

      <div class="nav-center">
        <select class="search-dropdown">
          <option>All</option>
          <option>All Departments</option>
          <option>Arts & Crafts</option>
        </select>
        <input type="twotabsearchtextbox" class="search-box" placeholder="Search Mummykart">
    <div class="search-icon"><i class="fas fa-search"></i></div>
  </div>

      <div class="nav-right">
        <div class="language-option">
          <div class="flag">
            <i class="fa-regular fa-flag"></i>
          </div>
          <select class="select-language">
            <option value="lan">EN</option>
            <option value="lan">ES</option>
            <option value="lan">AR</option>
          </select>
        </div>

        <div class="account-option">
          <p class="top-text">Hello, sign in</p>
          <select class="select-account">
            <option value="Account">Account & Lists</option>
            <option value="Account">Account & Lists</option>
            <option value="Account">Account & Lists</option>
          </select>
        </div>

        <div class="order-option">
          <p class="top-text">Returns</p>
          <p class="bottom-text">& Orders</p>
        </div>

        <div class="cart-option">
          <div class="cart-logo">
            <i class="fa-solid fa-cart-shopping"></i>
          </div>
          Cart
        </div>
      </div>
    </nav>
  </header>
