import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { FieldConfig } from "../field.interface";

@Component({
  selector: "calsoft-button",
  template: `
<div fxFlex="100" [formGroup]="group">
<button type="submit" mat-raised-button class="primary">{{field.label}}</button>
</div>
`,
  styles: []
})
export class ButtonComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
}
