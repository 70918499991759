import { Pipe, PipeTransform } from '@angular/core';
import { ConfigServiceService } from 'src/app/services/config-service.service';

@Pipe({
  name: 'dynamicRound'
})
export class DynamicRoundPipe implements PipeTransform {
  decimalDigit: any=2;


  constructor(
    private configService: ConfigServiceService
  ) {
    this.getConfigData();
  }

  transform(value: number): any {

    if (this.decimalDigit !== null) {
      return value.toFixed(this.decimalDigit);
    } else {
      return value.toFixed(2); 
    }
  }

  getConfigData() {
    this.configService.getConfigData().subscribe(
      data => {
        this.decimalDigit = data['decimalDigit'];
      
      }, 
      error => {
      }
    );
  }

}
