import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { conditionalValidator, comparisonValidator } from '../../utils/calsoft-validators';
import { CustomValidators } from '../../utils/custom-validators';
import { FieldConfig } from '../field.interface';

@Component({
  selector: 'calsoft-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {

  field: FieldConfig;
  group: UntypedFormGroup;
  display: boolean = false;
  constructor() { }
  ngOnInit() {

    if (this.group != undefined) {
      this.display = true;
    }

  }

  isRequired(fieldName: string): boolean {
    const control = this.group.get(fieldName);
    return control && control.hasError('required');
  }
  
}