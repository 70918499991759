import { UntypedFormGroup, UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';

export function emailValidator(control: UntypedFormControl): { [key: string]: any } {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: UntypedFormGroup) => {
        let password = group.controls[passwordKey];
        let passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true })
        }
    }
}

export function conditionalValidator(condition: (() => boolean), validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!condition()) {
            return null;
        }
        return validator(control);
    }
}

export function download(data: any) {

    //var filename=headers['filename'];
    var filename = 'order_invoice.pdf';
    var contentType = data.headers.get('content-type');
    var linkElement = document.createElement('a');
    try {
        var blob = new Blob([data.body], { type: contentType });
        var url = window.URL.createObjectURL(blob);

        linkElement.setAttribute('href', url);
        linkElement.setAttribute("download", filename);

        var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        });
        linkElement.dispatchEvent(clickEvent);
    } catch (ex) {

    }
}

export function validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        control.markAsTouched({ onlySelf: true });
    });
}


export function isNumeric(number: any) {

    const numericValue = parseFloat(number);
    
    if (typeof numericValue === 'number' && !isNaN(number)) {
        return true;
    } else {
        return false;
    }


}



export class comparisonValidator {
    /**
  * Must match validator
  *
  * @param controlPath A dot-delimited string values that define the path to the control.
  * @param matchingControlPath A dot-delimited string values that define the path to the matching control.
  */
    static mustMatch(controlPath: string, matchingControlPath: string): ValidatorFn {
        return (formGroup: UntypedFormGroup): null => {

            // Get the control and matching control
            const control = formGroup.get(controlPath);
            const matchingControl = formGroup.get(matchingControlPath);

            // Return if control or matching control doesn't exist
            if (!control || !matchingControl) {
                return;
            }

            // Delete the mustMatch error to reset the error on the matching control
            if (matchingControl.hasError('mustMatch')) {
                delete matchingControl.errors.mustMatch;
                matchingControl.updateValueAndValidity();
            }

            // Don't validate empty values on the matching control
            // Don't validate if values are matching
            if (this.isEmptyInputValue(matchingControl.value) || control.value === matchingControl.value) {
                return;
            }

            // Set the validation error on the matching control
            matchingControl.setErrors({ mustMatch: true });
        };
    }

    /**
 * Check for empty (optional fields) values
 *
 * @param value
 */
    static isEmptyInputValue(value: any): boolean {
        return value == null || value.length === 0;
    }

}

export const trimValidator: ValidatorFn = (control: UntypedFormControl) => {
    if (control.value.startsWith(' ')) {
        return {
            'trimError': { value: 'control has leading whitespace' }
        };
    }
    if (control.value.endsWith(' ')) {
        return {
            'trimError': { value: 'control has trailing whitespace' }
        };
    }
    return null;
};



export function noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;

    return isValid ? null : { 'whitespace': true };
}

export function numberToWordsNew(value: number): string {
    if (value === 0) return 'zero rupees only';
    if (value < 0) return 'minus ' + numberToWords(-value);
  
    let words = '';
    let numString = value.toString();
    let numLength = numString.length;
    let crore, lakh, thousand, remainder;
  
    // Crores
    if (numLength > 7) {
      crore = parseInt(numString.substring(0, numLength - 7), 10);
      numString = numString.substring(numLength - 7);
      numLength = numString.length;
      words += convertChunk(crore) + ' crore ';
    }
  
    // Lakhs
    if (numLength > 5) {
      lakh = parseInt(numString.substring(0, numLength - 5), 10);
      numString = numString.substring(numLength - 5);
      numLength = numString.length;
      words += convertChunk(lakh) + ' lakh ';
    }
  
    // Thousands
    if (numLength > 3) {
      thousand = parseInt(numString.substring(0, numLength - 3), 10);
      numString = numString.substring(numLength - 3);
      numLength = numString.length;
      words += convertChunk(thousand) + ' thousand ';
    }
  
    // Hundreds and below
    remainder = parseInt(numString, 10);
    words += convertChunk(remainder);
  
    return words.trim() + ' rupees only';
  }
  
  const units: string[] = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const teens: string[] = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
const tens: string[] = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
const thousands: string[] = ['', 'thousand', 'lakh', 'crore'];
export function convertChunk(value: number): string {
  let words = '';

  if (value >= 100) {
    words += units[Math.floor(value / 100)] + ' hundred ';
    value %= 100;
  }
  if (value >= 20) {
    words += tens[Math.floor(value / 10)] + ' ';
    value %= 10;
  }
  if (value >= 10) {
    words += teens[value - 10] + ' ';
  } else if (value > 0) {
    words += units[value] + ' ';
  }

  return words;
}

export function numberToWords(num: number): string {
  const a = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  if (num.toString().length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/(\d{2})(\d{2})(\d{3})(\d{3})/);
  if (!n) return '';
  let str = '';
  str += (n[1] != '00') ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + ' crore ' : '';
  str += (n[2] != '00') ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + ' lakh ' : '';
  str += (n[3] != '000') ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + ' thousand ' : '';
  str += (n[4] != '000') ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + ' ' : '';
  return str;
}

export function getPageSize(size) {
    if (size >= 10) {
      if (size > 10) {
        return [5, 10, 15];
      }
      else if (size <= 10 && size > 5) {
        return [5, 10];
      }
    }
    else if (size <= 10 && size > 5) {
      return [5, 10];
    }
    else {
      return null;
    }
  
  }