<!-- <div class="container">
    <h2 class="mt-5 mb-4">Angular Stripe Checkout Example</h2>
    <div class="col-md-5 mb-2">
      <button (click)="makePayment(15)" class="btn btn-danger btn-block">Pay $15</button>
    </div>
    <div class="col-md-5 mb-2">
      <button (click)="makePayment(25)" class="btn btn-primary btn-block">Pay $25</button>
    </div>
    <div class="col-md-5">
      <button (click)="makePayment(35)" class="btn btn-success btn-block">Pay $35</button>
    </div>
  </div> -->

  <div class="padding-l60 padding-b10">
    <button (click)="makePayment()"
     style="color:white" class="primary"
      mat-flat-button>PAY NOW</button>
  </div>

  
