import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constant } from 'src/app/constants/constant';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-razor-pay',
  templateUrl: './razor-pay.component.html',
  styleUrls: ['./razor-pay.component.scss'],
  providers: [WindowRefService]
})
export class RazorPayComponent implements OnInit {
  key: any;

  status:boolean=false;
  razorpayScriptLoaded: any=false;
  constructor(private winRef: WindowRefService,
    private configService :ConfigServiceService,
    private commonService:CommonService
    ) {}

  @Input() finalAmount: any;

  @Input() partialAmountEnabled : any;

  @Input() contact: any;

  @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {
    if (!this.razorpayScriptLoaded) {
      this.loadRazorpayScript();
    }
   
this.getConfigData();

  }

  loadRazorpayScript() {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = () => {
      this.razorpayScriptLoaded = true; 
            // Razorpay script has loaded successfully
      // You can now use Razorpay functionalities
      // Call a method in your service to set up Razorpay
    };
    script.onerror = (error) => {
      // Handle script loading error, if any
      console.error('Error loading Razorpay script:', error);
    };

    // Append the script to the document's head
    document.head.appendChild(script);
  }

  // this.ccAvenueServices.encryptdata(request).subscribe(
  //   data => {
  //   this.encRequestRes = data['response'];
  //       setTimeout(()=>{
  //           this.form.nativeElement.submit();
  //       },1000)
  //   }, error => {
  //   }
  //   );

  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.commonService.setCurrencyValue(data['currencyBase'])
          this.key = data['razorPayKey'];
          this.status=true;

          }, error => {
            this.key = 'rzp_test_mGa2SfsXusZ9u'
            this.status=true;
          }
          );
}


  createRzpayOrder() {
    this.payWithRazor();
  }
  public serverPath = environment.commonImageApi+"logo/";
  payWithRazor() {

    if( this.status){
    const options: any = {
      key: this.key,
      amount: Math.round(this.finalAmount)*100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: Constant.siteName, // company name or product name
      description: 'Payment Transaction',  // product description
      //image: this.serverPath+'logo.png', // company logo or product image
      prefill: {
        name: this.contact.name,
        contact: this.contact.contact,
        email: this.contact.email
      },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        address: "Razorpay Corporate Office"
      },
      theme: {
        color: "#3399cc"
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      this.emitResponse(response,options)
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress

    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();

  }
  }
  additionData: string;
  paymentObject: any;
  emitResponse(response,options){
    let paymentInformation = {}

    if(this.partialAmountEnabled==1){
      paymentInformation = {
        razorpay_payment_id: response['razorpay_payment_id'],
        currency: options['currency'],
        Partial_Payment:'Yes',
        Partial_Paid_Amount:this.finalAmount
  
      }
    }else{
      paymentInformation = {
        razorpay_payment_id: response['razorpay_payment_id'],
        currency: options['currency'],
  
      }
    }
   

    this.additionData = JSON.stringify(paymentInformation)

    this.paymentObject = {
      salesPaymentTransaction: [{
        txn_id: response['razorpay_payment_id'],
        txn_type: "customer",
        isClose: true
      }],
      salesFlatOrderPayment: {
        method: 'razor_pay',
        additionalInformation: this.additionData

      },
      paypalPaymentStatus: "Successful"

    }

     this.paymentStatus.emit(this.paymentObject);
  }

}
