import { Component, Input, OnInit } from '@angular/core';
import { Constant } from 'src/app/constants/constant';
import { MenuItems } from 'src/app/Core/menu/menu-items/menu-items';
import { AboutService } from 'src/app/services/about.service';
import {MatIconRegistry} from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { SupplierDialogComponent } from 'src/app/pages/supplier-registration/supplier-dialog/supplier-dialog.component';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'calsoft-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {




  @Input() data: any;
  public serverPath = environment.commonImageApi + "footerImage/";

  public serverPath1 = environment.commonImageApi + "footerBrands/";

  calsoft
  aboutData: any;
  public siteName = Constant.siteName
  showFooterIcon:any=0;
  footerIconData: any=[];
  menuData: any=[];
  display: boolean=false;
  footerLogoSrc: any;
  footerImageEnable: any=0;
  dspeedupNavigationEnable:any=0;
  footerBrandImg: any;

  constructor(
    private commonService:CommonService,
    public menuItems: MenuItems,
     private aboutService: AboutService,
     private matIconRegistry: MatIconRegistry,
     private domSanitizer: DomSanitizer,
     private configService:ConfigService,
     private dialog: MatDialog
     ) { 
    
      this.matIconRegistry.addSvgIcon(
        "Facebook",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/facebook.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Twitter",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/twitter.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Youtube",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/youtube.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Tumblr",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/tumblr.svg")
      );

      this.matIconRegistry.addSvgIcon(
        "Linkedin",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/linkedin.svg")
      );
      this.matIconRegistry.addSvgIcon(
        "Instagram",
        this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/instagram.svg")
      );  
     }
     currentYear: number = new Date().getFullYear();

  ngOnInit() {

    if(window.innerWidth>959){
      this.display=true;
    }

    if(this.data.footerLogoSrc!=undefined){
      this.footerLogoSrc=this.data.footerLogoSrc;
    }

    if (this.data.footerBrandImg != undefined) {
      this.footerBrandImg = this.data.footerBrandImg;
    }

    if(this.data.footerImageEnable!=undefined){
      this.footerImageEnable=this.data.footerImageEnable;
    }

    if(this.data.dspeedupNavigationEnable!=undefined){
      this.dspeedupNavigationEnable=this.data.dspeedupNavigationEnable;
    }

    this.getAboutData();

    if(this.data.showFooterIcon==1){

      this.showFooterIcon=1;
      this.getFooterIcon();
    }

    this.getFooterMenuData()
    

  }

  getFooterMenuData(){

    if((this.data.showContactPage==1&&this.data.showBlogPage==0)){

      this.menuData=this.menuItems.getFooterOneMenu()
    }else if(this.data.showContactPage==0 &&this.data.showBlogPage==0 ){
      this.menuData=this.menuItems.getFooterTwoMenu()
    }else if(this.data.showContactPage==0 &&this.data.showBlogPage==1 ){
      this.menuData=this.menuItems.getFooterThirdMenu()
    }else if(this.data.showContactPage==1 &&this.data.showBlogPage==1 ){
      this.menuData=this.menuItems.getFooterFourthMenu()
    }
  }

  
  getCoupon(){
    if(this.data.showCouponPage==1){
      this.menuData=this.menuItems.getFooterFourthMenu()
    }
    // else if (this.data.showCouponPage==0){
    //   this.menuData=this.menuItems.getFooterFourthMenu()
    // }
  }

  public getCalsoft() {
    this.aboutService.getCalsoft().subscribe(data => {
      this.calsoft = data;
    })
  }


  getAboutData() {
    this.aboutService.getPageShortAbout(Constant.shortAbout).subscribe(
      (res) => {
        this.aboutData = res['content'];
      }
    )
  }

  getFooterIcon() {
    this.configService.getFooterData()
      .subscribe(
        data => {

        
          if(data['datalist'].length==0){
            this.showFooterIcon=0;
            this.footerIconData=[];
          }else{
            this.footerIconData=data['datalist'];
          }

          if(this.data.footerLogoSrc!=undefined){
            this.footerLogoSrc=this.data.footerLogoSrc;
          }
          
          }, error => {

          }
          );
}

navigate(data:any) {

if(data.linkNavigated==1){

  if(data.link!=null){
    let   url = data.link
    window.location.href=url;
  }
  
}


 
}

openDialog() {
  let dialogRef = this.dialog.open(SupplierDialogComponent, {
    width: '350px',
    disableClose: true,
  });
  dialogRef.disableClose = true;
}

Supplierlogin() {
  if (window.innerWidth > 768) {

    let dialogRef = this.dialog.open(SupplierDialogComponent, {
    width: '790px',
    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
    this.ngOnInit();


    });

  }
  else {
    let dialogRef = this.dialog.open(SupplierDialogComponent, {
    minWidth: '100vw', height: '100vh',

    });

    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
    this.ngOnInit();


    });
  }

  }

  composeEmail(smtpUsername) {
    window.location.href = 'mailto:' + smtpUsername;
  }

  getDirections(streetAddress:string): void{
    if (streetAddress && streetAddress.toLowerCase() !== 'none') {
      let url = streetAddress.toLowerCase();
      
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'http://' + url;
      }
  
      this.commonService.goToLink(url);

    }
}



gotoLink(url: string) {
  if (url) {
    const fullURL = this.prependProtocol(url);
    this.commonService.goToLink(url)

  }
}

prependProtocol(url: string): string {
  if (!url.includes('http://') && !url.includes('https://')) {
    return 'https://' + url;
  }
  return url;
}

imageLoaded: boolean = true; // Initially true to attempt loading the image

  onImageError() {
    this.imageLoaded = false; // Hide the image if it fails to load
  }

}
