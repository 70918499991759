import { Pipe, PipeTransform } from '@angular/core';
import { ConfigServiceService } from 'src/app/services/config-service.service';

@Pipe({
  name: 'characterPipe'
})
export class CharacterPipe implements PipeTransform {

  categoryMenuDisplayType: any = 3;


  constructor(
    private configService: ConfigServiceService
  ) {
    this.getConfigData();
  }

  transform(value: string): string {
    if (this.categoryMenuDisplayType == 1) {
      return value.toUpperCase();
    } else if (this.categoryMenuDisplayType == 2) {
      return value.toLowerCase();
    } else if (this.categoryMenuDisplayType == 3) {
      return value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    } else {
      return value;
    }
  }


  getConfigData() {
    this.configService.getConfigData().subscribe(
      data => {
        this.categoryMenuDisplayType = data['categoryMenuDisplayType'];

      },
      error => {
      }
    );
  }

}
