import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/utils/auth.guard';

import { ReviewRatingComponent } from './review-rating.component';


const routes: Routes = [
  {
  path: 'review/:productId/:type',
  component: ReviewRatingComponent,
  canActivate: [AuthGuard],
  data: { breadcrumb: 'Review' },
  },
{
  path: 'review/:productId/:subProductId/:type',
  component: ReviewRatingComponent,
  canActivate: [AuthGuard],
  data: { breadcrumb: 'Review' 
}
}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReviewRatingRoutingModule { }
