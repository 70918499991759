import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constant } from 'src/app/constants/constant';
import { AboutService } from 'src/app/services/about.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-newsletter-ads2',
  templateUrl: './newsletter-ads2.component.html',
  styleUrls: ['./newsletter-ads2.component.scss']
})
export class NewsletterAds2Component {
  firstFormGroup: UntypedFormGroup;

  @Input() subscribeText: any;

  public serverPath = environment.commonImageApi + "adsHomePageImage/";

  public serverPath1 = environment.commonImageApi + "logo/";

  adsHomePageImage: any;
  adsHomePageTitle: any;
  adsHomePageDescription: any;

  @Input() data: any;

  constructor(
    private configService: ConfigServiceService,
    private dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private aboutService: AboutService,
    ) {}

  close(){
    this.dialog.closeAll();
  }

  ngOnInit() {

    this.firstFormGroup = this.formBuilder.group({
      'subscriberId': ['',],
      'storeId': ['',],
      'changeStatusAt': ['',],
      'customerId': ['',],
      'subscriberEmail': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
      'subscriberStatus': ['',],
      'subscriberConfirmCode': ['',],
    });

    this.getConfigData();

  }


getConfigData() {
  this.configService.getConfigData()
    .subscribe(
      data => {

        this.adsHomePageImage=data['adsHomePageImage'];

        this.adsHomePageTitle=data['adsHomePageTitle'];

        this.adsHomePageDescription=data['adsHomePageDescription'];

      }, error => {

      }
    );
}




}
