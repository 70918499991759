<mat-toolbar class="mobileheader">

    <mat-toolbar-row style="padding: 0px;" fxLayout="row" [style.margin]="mobileHeaderLogoGap"
        [style.height]="mobileHeaderHeight">
        <div fxFlex="10%">
            <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock"
             class="responsive-toggle" mat-icon-button (click)="toggleSidebar()">
                <i style="font-size: 18px" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
            </button>
        <ng-template #elseBlock>
            <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button
                (click)="back()">
                <i class="material-icons ">keyboard_arrow_left</i>
            </button>
        </ng-template>
        </div>
        <div fxFlex="30%">

            <section *ngIf="layout==1&&mobileLogoEnable==0" fxLayout="row" fxLayoutAlign="center center">

                <calsoft-logo [sitename]="data.siteName" *ngIf="!displaySearchBar" fxFlex fxLayoutAlign="center center"
                    [type]="'light'"></calsoft-logo>

            </section>

            <div *ngIf="layout==1&&mobileLogoEnable==1" fxLayoutAlign="center center">
                <img *ngIf="!displaySearchBar" style="width: 100%;" [src]="serverPath3+mobileLogoSrc" fxLayoutAlign="center center"
                    onerror="this.src='';" />
            </div>

            <mat-divider *ngIf="url == '/cart'"></mat-divider>
        </div>
        <div fxFlex="10%">
        </div>
        <div fxFlex="70%">
            <div style="width: 100%;" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutWrap="wrap">
                
                <calsoft-Menu  [data]="data" style="z-index: 1000;width: 100%;" [type]="'one'" [categoryArray]="list1"></calsoft-Menu>
            </div>
              
        </div>

    </mat-toolbar-row>
    <mat-toolbar-row style="padding: 0px;" fxLayout="row" [style.margin]="mobileHeaderLogoGap"
    [style.height]="mobileHeaderHeight">

    <div fxFlex="30%">
                <button (click)="openCitySelectionDialog()" class="slc">
                    {{ selectedCity ? selectedCity.name : 'Select City' }}
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
    </div>
   

    <div fxFlex="70%">
        <div>
                <calsoft-search fxLayoutAlign="center center" [searchbarborderRadius]="'20px'"></calsoft-search>
        </div>
    </div>

   </mat-toolbar-row>





</mat-toolbar>
