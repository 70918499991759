<mat-toolbar class="mobileheader">
    <mat-toolbar-row style="padding: 0px;" fxLayout="row" [style.margin]="mobileHeaderLogoGap"
        [style.height]="mobileHeaderHeight">

        <!-- logo Start -->

        <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

            <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock" class="responsive-toggle"
                mat-icon-button (click)="toggleSidebar()">
                <i style="font-size: 18px" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
            </button>
            <ng-template #elseBlock>
                <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button
                    (click)="back()">
                    <i class="material-icons ">keyboard_arrow_left</i>
                </button>
            </ng-template>



            <div *ngIf="layout==0&&mobileLogoEnable==0" fxLayoutAlign="start center">
                <calsoft-logo [sitename]="data.siteName" *ngIf="!displaySearchBar" [type]="'light'"></calsoft-logo>
            </div>

            <div *ngIf="layout==0&&mobileLogoEnable==1" fxLayoutAlign="start center">
                <img *ngIf="!displaySearchBar" [src]="serverPath3+mobileLogoSrc" style="width: 100%;" onerror="this.src='';" />
            </div>

        </section>

        <div *ngIf="url != '/cart' && layout==0" [fxFlex]="layout==0?5:0">

        </div>


        <section *ngIf="layout==0" fxFlex="55" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px">

            <div *ngIf="data.disableSearchInHeader==0">
                <div *ngIf="url != '/cart'">
                    <mat-icon style=" cursor: pointer;" [style.color]="data.headerIconColour"
                        fxLayoutAlign="start center" *ngIf="!displaySearchBar"
                        (click)="showSearchBar()">search</mat-icon>
                </div>


                <div *ngIf="displaySearchBar" class="search-bar" fxLayout="row" fxLayoutAlign="end center" fxFlex
                    fxFlex.gt-sm="350px" fxLayoutGap="5px" style="margin-right: 15px;">
                    <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                        [searchbarborderRadius]="'15px'"></calsoft-search>
                </div>
            </div>


            <div *ngIf="disableLoginHeader==0">
                <div class="black" (click)="SignIn('login')" *ngIf="!userLoggedIn" fxLayout="row"
                    fxLayoutAlign="center center">
                    <button mat-button style="padding: 20px;" *ngIf="!displaySearchBar" matSuffix (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [style.color]="data.headerIconColour"
                        [attr.aria-pressed]="hide">
                        Sign In
                        <mat-icon>person</mat-icon>
                    </button>
                </div>
            </div>

            <calsoft-HeaderUserProfileDropdown [data]="data" [style.color]="data.headerIconColour" class="headerUser"
                *ngIf="userLoggedIn">
            </calsoft-HeaderUserProfileDropdown>

            <section *ngIf="disableCartIconInHeader==0">
                <calsoft-cart-icon *ngIf="!displaySearchBar" fxFlex
                    [style.color]="data.headerIconColour"></calsoft-cart-icon>
            </section>

        </section>

        <!-- logo  Center -->

        <div  [fxFlex]="layout==1?18:0">

        </div>

        <section *ngIf="layout==1&&mobileLogoEnable==0" fxLayout="row" fxLayoutAlign="center center">

            <calsoft-logo [sitename]="data.siteName" *ngIf="!displaySearchBar" fxFlex fxLayoutAlign="center center"
                [type]="'light'"></calsoft-logo>

        </section>

        <div *ngIf="layout==1&&mobileLogoEnable==1" fxLayoutAlign="center center">
            <img *ngIf="!displaySearchBar" [src]="serverPath3+mobileLogoSrc" style="width: 100%;" fxLayoutAlign="center center"
                onerror="this.src='';" />
        </div>

        <mat-divider *ngIf="url == '/cart'"></mat-divider>

        <div [fxFlex]="layout==1?18:0">

        </div>

        <section *ngIf="layout==1" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">

            <div fxLayoutAlign="end end" *ngIf="data.disableSearchInHeader==0">

                <div *ngIf="url != '/cart'&& url !='/checkout'">
                    <mat-icon [style.color]="data.headerIconColour" style=" cursor: pointer;" fxLayoutAlign="end end"
                        *ngIf="!displaySearchBar" (click)="showSearchBar()">search</mat-icon>
                </div>

                <div *ngIf="displaySearchBar" class="search-bar" fxLayout="row" fxLayoutAlign="space-between center">
                    <calsoft-search (callhideSearchBar)="hideSearch($event)" showSearchBar
                        [searchbarborderRadius]="'15px'"></calsoft-search>
                </div>

            </div>
            <calsoft-cart-icon *ngIf="disableCartIconInHeader==0" fxFlex
                [style.color]="data.headerIconColour"></calsoft-cart-icon>
        </section>

    </mat-toolbar-row>



</mat-toolbar>




<mat-toolbar style="padding:0px ; height: 43px;" fxHide fxShow.lt-md class="calsoft-fixed-header primary" fxLayout="row"
    fxLayoutAlign="space-between center">
    <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

        <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock" class="responsive-toggle"
            mat-icon-button (click)="toggleSidebar()">
            <i style="font-size: 18px" class="material-icons ">menu</i>
        </button>
        <ng-template #elseBlock>
            <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button (click)="back()">
                <i class="material-icons ">keyboard_arrow_left</i>
            </button>
        </ng-template>
    </section>
    <section fxFlex *ngIf="data.disableSearchInHeader==0">
        <calsoft-search></calsoft-search>
    </section>
    <section>
        <calsoft-cart-icon *ngIf="disableCartIconInHeader==0" [style.color]="data.headerIconColour"></calsoft-cart-icon>
    </section>
</mat-toolbar>
<!-- Mobile View  end -->