import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';

import { EcryptServiceService } from './ecrypt-service.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  url = environment.serverAPI3 + "/" + "api";
  url2 = environment.serverAPI3 + "/" + "api/ig";
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private ecryptServiceService:EcryptServiceService,
    ) { }

  getAllPaymentModes(): Observable<Response[]> {
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    let authourized = this.userService.getAuthenticatedUser();
    if(guestLoginEnabled=="0"||authourized){
    return this.http.get<any>(`${this.url}/payment-mode`);
    }else{
     
      return this.http.get<any>(`${this.url2}/payment-mode`);
    }
  }



    phonePePayment(url,request) {

      const jsonString = JSON.stringify(request);
     const base64String = window.btoa(jsonString)
    let payload = {
      "request": base64String,
    }

    // const hash = crypto.createHash('sha256').update
    // (base64String + "/pg/v1/pay" + '7887c825-8ad1-49e5-b49a-5133a2e8cec2').digest('hex');


    let hash=this.ecryptServiceService.sha256(base64String);

    let value=hash+ '###' + 1
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "X-VERIFY":value
    });

  
    let options = {
      headers: headers,
      body: payload,
    };
    //return this.http.post(`${url}`, options);

    this.http.post(url, payload, { headers: headers })
  .subscribe(response => {
    console.log(response);
    return response;
  }, error => {
    console.error(error);
  });

   
  
  }
}
