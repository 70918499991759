import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayService {

  url = environment.serverAPI3 + "/api/payment";

  url1 = environment.serverAPI3 + "/" + "api/payment/ig";

  constructor( private http: HttpClient) { }


}
