import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewSubscriptionService {

  constructor(private http: HttpClient) { }

  url = environment.serverAPI4 + "/api/catalog/ruhisubscription";

  saveSubscriptionForm(object: any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}`, object);
  }

}
