<span (click)="close()" style="padding: 0px ; HEIGHT: 0PX;margin: 0px;" mat-dialog-title>
    <span (click)="close()" fxLayout="row" fxLayoutAlign="end center">
        <button style="background-color:white; border-radius:20px;" (click)="close()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </span>
</span>


<div>
    <mat-dialog-content style=" margin: 0px;  max-height: 100%;
    padding: 0px;">
        <div style="width:100%;">
            <img style="width:100%;" [src]="serverPath + adsHomePageImage" flex="100"
                [alt]="serverPath + adsHomePageImage" />
        </div>

    </mat-dialog-content>
</div>
<!-- 
<div class="dialog-container">

    <span class="close-button" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>

</div> -->