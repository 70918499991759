import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { conditionalValidator, comparisonValidator } from '../../utils/calsoft-validators';
import { CustomValidators } from '../../utils/custom-validators';
import { FieldConfig } from '../field.interface';

@Component({
  selector: 'app-password-confirm',
  templateUrl: './password-confirm.component.html',
  styleUrls: ['./password-confirm.component.scss']
})
export class PasswordConfirmComponent  implements OnInit {

  field: FieldConfig;
  group: UntypedFormGroup;
  display: boolean = false;
  passwordValidationRequired: number;
  forgotPasswordForm: UntypedFormGroup;
  constructor(    public formBuilder: UntypedFormBuilder,) { }
  ngOnInit() {

    if (this.group != undefined) {
      this.display = true;
    }

    this.initForgotPasswordForm();

  }

  isRequired(fieldName: string): boolean {
    const control = this.group.get(fieldName);
    return control && control.hasError('required');
  }

  
  disable: boolean=false;
  passwordMinimumCharacters: any;
  isProgrammaticChange: boolean = false;
  initForgotPasswordForm(){

    this.passwordMinimumCharacters=8;
    this.passwordValidationRequired=1;
    //this.passwordMinimumCharacters=this.passwordValidationRequired==1?8:this.passwordMinimumCharacters
    this.forgotPasswordForm = this.formBuilder.group({
      'password': [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          ),

          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          ),
          // check whether the entered password has a lower case letter
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          ),
          // check whether the entered password has a special character
          conditionalValidator(
            (() => this.passwordValidationRequired == 1),
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          ),

          Validators.minLength(this.passwordMinimumCharacters)

        ])
      ],
      'confirmPassword': ['', Validators.required],
    }, { validator: comparisonValidator.mustMatch('password', 'confirmPassword') }

    );


    
  }
  setValueOnFormValid(): void {
    if (this.forgotPasswordForm.valid) {
      // Set flag to true to indicate programmatic change
      this.isProgrammaticChange = true;
  
      // Set value to your field here
      this.group.get(this.field.name).setValue(this.forgotPasswordForm.value.confirmPassword);
  
    
    }else{
      this.isProgrammaticChange = false;
      this.group.get(this.field.name).reset();
    }
  }
}
