import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ReviewRatingService {
	constructor(private http: HttpClient) { }
	url = environment.serverAPI3 + "/api/review";
	url1 = environment.serverAPI3 + "/api/rating";

	//call not neededd
	getReview(): Observable<Object> {
		return this.http.get(`${this.url}`);
	}

	getReviewById(productId: number): Observable<Object> {
		return this.http.get(`${this.url}/details/${productId}`);

	}

	getReviewByIdSubId(productId: number,subProductId:any): Observable<Object> {
		return this.http.get(`${this.url}/details/${productId}/${subProductId}`);

	}

	createReview(review): Observable<Response[]> {
		return this.http.post<Response[]>(`${this.url}`, review);
	  }
	

	  
	getRatings(): Observable<Object> {
		return this.http.get(`${this.url1}`);
	}

	getProductDetailReviewAll(productId: number): Observable<Object> {
		let	url = environment.serverAPI3 + "/api/catalog";
		if(productId!=null){{
			return this.http.get(`${url}/reviewdetail/${productId}`);
		}}
		
	  }

	  getProductDetailReviewVariant(productId: number,subProductId:any): Observable<Object> {
		let	url = environment.serverAPI3 + "/api/catalog";
		if(productId!=null){{
			return this.http.get(`${url}/reviewdetail/${productId}/${subProductId}`);
		}}
		
	  }

}
