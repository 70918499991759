<div class="container">
    <div fxLayout="row" fxLayoutAlign="end end">
        <div *ngIf="type==1" fxLayout="column" fxLayoutAlign="space-evenly start" class="cost-container">
            <div class="cost-item" fxLayout="row">
                <h3>Subscription cost - </h3>
                <h3>{{subscriptionCost}}</h3>
            </div>
            <div class="cost-item" fxLayout="row">
                <h3>GST - </h3>
                <h3>{{taxAmount}}</h3>
            </div>
            <div class="cost-item" fxLayout="row">
                <h3>Total - </h3>
                <h3>{{totalCost}}</h3>
            </div>
            <!-- <div class="cost-item" fxLayout="row">
                <h3> Cost Per Room Per Day - </h3>
                <h3>00</h3>
            </div> -->
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end">
        <div *ngIf="type==2" fxLayout="column" fxLayoutAlign="space-evenly start" class="cost-container">
            <div fxLayout="column" fxLayoutAlign="start">
                <div class="cost-item" fxLayout="row">
                    <h3>Total Cost -</h3>
                    <h3>{{totalGrandTotalWithoutTax || 0.0}}</h3>
                </div>
                <div class="cost-item" fxLayout="row">
                    <h3>SGST / IGST - </h3>
                    <h3>{{totalTax || 0.0}} </h3>
                </div>
            </div>
            <div fxLayoutAlign="end end">
                <h3> Grand total </h3>
                <h3>{{totalGrandTotal || 0.0}} </h3>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="type==3" fxLayout="column" fxLayoutAlign="space-evenly start" class="cost-container2">
                <div fxLayoutAlign="center center" style="width:100% ;">
                    <h2>TOTAL ESTIMATE COST</h2>
                </div>
                <div fxLayout="column" fxLayoutAlign="start" style="width:100% ;">
                    <div class="cost-item" fxLayout="row">
                        <h3 fxFlex="50"> Subscription Cost :</h3>
                        <h3 fxFlex="50">{{subscriptionCost}}</h3>
                    </div>
                    <div class="cost-item" fxLayout="row">
                        <h3 fxFlex="50"> Additional Ammenities & Supplies :</h3>
                        <h3 fxFlex="50">{{totalGrandTotalWithoutTax|| 0.0}}</h3>
                    </div>
                    <div class="cost-item" fxLayout="row">
                        <h3 fxFlex="50"> SGST / IGST :</h3>
                        <h3 fxFlex="50">{{totalTax+taxAmount || 0.0}}</h3>
                    </div>
                </div>

                <div  *ngIf="formValid"  fxLayoutAlign="center center" style="width:100% ;">
                    <app-razor-pay [contact]="contact" [partialAmountEnabled]="0" 
                    (paymentStatus)="paymentResponceRazorPAy($event)"
                     [finalAmount]="subscriptionCost+totalGrandTotalWithoutTax+totalTax+taxAmount"></app-razor-pay>
                </div>
            </div>
    </div>
</div>