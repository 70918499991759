import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'calsoft-book-popup',
  templateUrl: './book-popup.component.html',
  styleUrls: ['./book-popup.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      state('open', style({
        opacity: 1,
        transform: 'scaleY(1)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'scaleY(0)'
      })),
      transition('open <=> closed', [
        animate('0.3s ease-in-out')
      ])
    ]),
    trigger('dropdownCode', [
      state('open', style({
        opacity: 1,
        transform: 'scaleY(1)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'scaleY(0)'
      })),
      transition('open <=> closed', [
        animate('0.3s ease-in-out')
      ])
    ])
  ]
})
export class BookPopupComponent implements OnInit{
  startDate: Date;
  endDate: Date;
  guestCount: string;
  isOpen1: boolean;
  value: string;
  specialcat:any;
  bookingInfo: any[] = [{ info: 'Room 1 - 1 Adult', adults: 1,children:0 }];

  ngOnInit() {
    this.updateBookingInfo();
  }

  incrementAdult(room: any) {
    room.adults++;
    this.updateBookingInfo();
  }
  
  decrementAdult(room: any) {
    if (room.adults > 1) {
      room.adults--;
    }
    this.updateBookingInfo();
  }

  updateBookingInfo() {

    let adult=0;
    let room =0;
    let children=0
    this.bookingInfo.forEach((room, index) => {
   
      if(index==0){
        adult=room.adults;
        children=room.children;
        room=1;
      }else{
        adult=adult+room.adults;
        children=children+room.children;
        room=1+1;
      }

      room= index + 1;
      
    });

     room=this.bookingInfo.length;
    this.guestCount= adult+"Adult"+children+"Children"+room+"Room"
  }


  addRoom() {
    this.bookingInfo.length + 1;
    //this.bookingInfo.push({ info: Room ${this.bookingInfo.length + 1} - 1 Adult, adults: 1 ,children:0 });
    this.updateBookingInfo()
   
  }

  removeRoom(index: number) {
    this.bookingInfo.splice(index, 1);
    this.updateBookingInfo();
  }


  incrementChildren(room: any) {
    room.children++;
    this.updateBookingInfo()
  }
  
  decrementChildren(room: any) {
    if (room.children > 1) {
      room.children--;
    }
    this.updateBookingInfo()
  }
  
  showBottomDiv: boolean = false;

  toggleBottomDiv() {
    this.showBottomDiv = !this.showBottomDiv;
  }

  @Output() itemSelected = new EventEmitter<string>();
  isOpen = false;

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  toggleMenu1() {
    this.isOpen1 = !this.isOpen1;
  }

  onItemClick(item: string) {
    this.itemSelected.emit(item);
    this.isOpen = false; // Close the menu after selecting an item
  }


}
