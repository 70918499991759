import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { CartService } from 'src/app/services/cart.service';


@Component({
  selector: 'calsoft-owa-status',
  templateUrl: './owa-status.component.html',
  styleUrls: ['./owa-status.component.scss']
})
export class OwaStatusComponent implements OnInit {

  constructor(  private router: Router,
    private snackBar:MatSnackBar,
    private cartService:CartService) { }

  ngOnInit(): void {


    let value:any=this.router.url;

    value=value.split("pay?id=");


 value=value[1].split("&")[0];


 let object={
   id:value
 }

 this.cartService.getPaymentStatus(object).subscribe(
  (response) => {


 if(response['status_code']==Constant.RESPONSE_SUCCESS){
  localStorage.removeItem(Constant.CART_COUNT)
  this.cartService.responseCache1= new Map();
  this.snackBar.open(response['status_message'], '×', 
  { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
  sessionStorage.setItem('CARTCOUNT', "0");
  this.router.navigate(['/orders']);
 }else{
  this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
  this.router.navigate(['/']);
 }



  },
  error => {
  }
)

  }

}
