<!-- Normal view end -->
<!-- Mobile view start-->

<mat-toolbar class="mobileheader">
    <mat-toolbar-row [style.margin]="mobileHeaderLogoGap" [style.height]="mobileHeaderHeight" fxLayout="row"
        fxLayoutAlign="space-between center">
        <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

            <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock" class="responsive-toggle"
                mat-icon-button (click)="toggleSidebar()">
                <i style="font-size: 18px;" [style.color]="data.headerIconColour" class="material-icons ">menu</i>
            </button>
            <ng-template #elseBlock>
                <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button
                    (click)="back()">
                    <i class="material-icons ">keyboard_arrow_left</i>
                </button>
            </ng-template>

            <div *ngIf="layout==0&&mobileLogoEnable==0" fxLayoutAlign="start center">
                <calsoft-logo [sitename]="data.siteName" fxFlex [type]="'light'"></calsoft-logo>
            </div>

            <div *ngIf="layout==0&&mobileLogoEnable==1" fxFlex fxLayoutAlign="start center">
                <img [src]="serverPath3+mobileLogoSrc" onerror="this.src='';" style="height: 100px;width: 100%;"
                    [ngStyle]="{'height': '150px'}" />
            </div>

        </section>

        <section *ngIf="layout==1&&mobileLogoEnable==0" fxLayout="row" fxLayoutAlign="center center">
            <calsoft-logo [sitename]="data.siteName" fxFlex fxLayoutAlign="center center"
                [type]="'light'"></calsoft-logo>

        </section>

        <div *ngIf="layout==1&&mobileLogoEnable==1" fxFlex fxLayoutAlign="center center">
            <img [src]="serverPath3+mobileLogoSrc" fxLayoutAlign="center center" onerror="this.src='';"
                style="height: 100px; width: 100%;" [ngStyle]="{'height': '150px'}" />
        </div>

        <section *ngIf="layout==0" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px">
            <div class="white" (click)="SignIn('login')" *ngIf="!userLoggedIn" fxLayout="row"
                fxLayoutAlign="center center">
                <div *ngIf="disableLoginHeader==0">
                    <button mat-button style="padding: 2px;" matSuffix (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                        Sign In
                        <mat-icon>person</mat-icon>
                    </button>
                </div>
            </div>
            <calsoft-HeaderUserProfileDropdown [style.color]="data.headerIconColour" class="headerUser"
                *ngIf="userLoggedIn">
            </calsoft-HeaderUserProfileDropdown>

            <calsoft-cart-icon *ngIf="disableCartIconInHeader==0"
                [style.color]="data.headerIconColour"></calsoft-cart-icon>

        </section>

        <section *ngIf="layout==1" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="2px">
            <div  class="padding-10"  *ngIf="referer.includes('medtreat.')" fxLayoutAlign="end center">
                <button mat-raised-button style="color: white;" class="primary btncss" (click)="navigateTo('/pages/enquiry')">Enquire Now
                </button>
            </div>
        </section>

    </mat-toolbar-row>

        <mat-toolbar-row *ngIf="url!='/cart'&&url!='/checkout/type'
    &&url!='/checkout' && data.disableSearchInHeader==0" style="padding: 0px 10px 10px 10px ; height: 43px;">
            <calsoft-search></calsoft-search>
        </mat-toolbar-row>
</mat-toolbar>

<mat-toolbar style="padding:0px ; height: 43px;" fxHide fxShow.lt-md class="calsoft-fixed-header mobileheader" fxLayout="row"
    fxLayoutAlign="space-between center">
    <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">

        <button [style.color]="data.headerIconColour" *ngIf="url=='/home';else elseBlock" class="responsive-toggle" mat-icon-button
            (click)="toggleSidebar()">
            <i style="font-size: 18px" class="material-icons ">menu</i>
        </button>
        <ng-template #elseBlock>
            <button [style.color]="data.headerIconColour" class="responsive-toggle " mat-icon-button (click)="back()">
                <i class="material-icons ">keyboard_arrow_left</i>
            </button>
        </ng-template>
    </section>
    <section fxFlex *ngIf="data.disableSearchInHeader==0">
        <calsoft-search></calsoft-search>
    </section>
       <section>
        <calsoft-cart-icon *ngIf="disableCartIconInHeader==0" [style.color]="data.headerIconColour"></calsoft-cart-icon>
    </section>
</mat-toolbar>
<!-- Mobile View  end -->