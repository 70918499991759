import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ConfigServiceService } from 'src/app/services/config-service.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'calsoft-footer-header',
  templateUrl: './footer-header.component.html',
  styleUrls: ['./footer-header.component.scss']
})
export class FooterHeaderComponent {


  @Input() data: any;
  display: boolean;
  enableFooterWhatsapp: any = 0;
  

  constructor(
    private commonService:CommonService,
    public router: Router,
    private configService: ConfigServiceService,
    ) {

    }
  ngOnInit() {

    if(this.router.url == '/' || this.router.url == '/home'){

      this.display=true;
    }else{
      this.display=false;
    }

    this.getConfigData();
    
    
  }


  getConfigData() {
    this.configService.getConfigData()
      .subscribe(
        data => {
          this.enableFooterWhatsapp=data['enableFooterWhatsapp'];
        }, error => {

        }
      );
  }

  callPhone() {
    let phoneNumber = "tel:" + this.data.footerScrollCallUsNo;
    window.location.href = phoneNumber;
  }


  whatsapp() {
    let message = `Hi Sir`;
    let url = `https://api.whatsapp.com/send?phone= ${encodeURIComponent(this.data.storePhoneNumber)}&text=${encodeURIComponent(message)}`;
    this.commonService.goToLink(url);

  }


}
