<mat-form-field fxFlex="100"  appearance="outline"  [formGroup]="group">
    <mat-label>{{field.label}}
        <span *ngIf="isRequired(field.name)" class="required-symbol">*</span>
    </mat-label>
    <input matInput [formControlName]="field.name" [type]="field.inputType">
    
    <ng-container *ngFor="let validation of field['dynamicFormLabelValidator'];" ngProjectAs="mat-error">
    <mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>
    </ng-container>
    
    <mat-hint *ngFor="let validation of field['dynamicFormLabelValidator'];">{{validation.hintMessage}}</mat-hint>
    
</mat-form-field>