
<div class="positionFix11"  *ngIf="display" >


<div>
    <div fxHide fxShow.md fxShow.lg fxShow.gt-lg fxFlex="10">
    </div>

    <div fxFlex  fxLayoutAlign="center center">
        <button *ngIf="enableFooterWhatsapp==0 || enableFooterWhatsapp==1" (click)="callPhone()" fxFlex class="btn">
        <mat-icon style="margin-right: 5px;">phone_iphone</mat-icon>
           Call Us
        </button>
        <button *ngIf="enableFooterWhatsapp==0 || enableFooterWhatsapp==2" (click)="whatsapp()" fxFlex class="btn">
            <img style="height: 24px; margin-right: 5px;"  src="assets/icons/whatsapp.svg" alt="whatsapp">
            Whatsapp Us
        </button>
    </div>
    <div fxHide fxShow.md fxShow.lg fxShow.gt-lg fxFlex="10">
    </div>

</div>

<div [style.background-color]="data.footerScrollTextBackgourndColor" class="promo-top w-100 h-auto text-center theme-container">
    <div>
        <div class="flex-container marquee-container">
            <h4 [style.color]="data.footerScrollTextColor" class="font-weight-normal text-uppercase m-0 marquee-text">
                {{data.footerScrollText}}
            </h4>
        </div>
    </div>
</div>

<div style="margin-bottom: 5px;">
    
</div>
</div>