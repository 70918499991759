import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constant } from 'src/app/constants/constant';
import { PaymentGatewayService } from 'src/app/services/payment-gateway.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';


declare var EasebuzzCheckout: any;

@Component({
  selector: 'calosft-easebuzz',
  templateUrl: './easebuzz.component.html',
  styleUrls: ['./easebuzz.component.scss']
})
export class EasebuzzComponent implements OnInit  {

  paymentObject: any;

  @Input() finalAmount: any;

  @Input() data: any;

  @Input() order: any;

  @Input() config: any;

  @Input() partialAmountEnabled : any;

  additionData: string;

  @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>();
  email: string;
  scriptLoaded: boolean=false;

  constructor(private paymentGatewayService:PaymentGatewayService,
    private userService: UserService,
    private http: HttpClient
    ){
    
  }

  ngOnInit(): void {
    let value=  this.email=localStorage.getItem('authenticaterUserEmail');
    if(value!=null){
     this.email=localStorage.getItem('authenticaterUserEmail');
    }else{
    
     this.email="ram@gmail.com"
     
    }
   // this.loadScript();
   this.initiatePayment();
  }

  loadScript() {
    const script = document.createElement('script');
    script.src = 'https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js';
    script.async = true;
    script.onload = () => {
      this.scriptLoaded = true; 
     
            // Razorpay script has loaded successfully
      // You can now use Razorpay functionalities
      // Call a method in your service to set up Razorpay
    };
    script.onerror = (error) => {
      // Handle script loading error, if any
      console.error('Error loading Razorpay script:', error);
    };

    // Append the script to the document's head
    document.head.appendChild(script);
  }

  initiatePayment(){
   
    let object={
      email:this.email,
      name:this.data.firstName,
      phone:this.data.telephone,
      productInfo:"Shopping",
      amount:this.finalAmount 
    }
    
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    let baseurl="/api/payment/ig/easebuzz/payment-details"
    if(guestLoginEnabled=="0"||authourized){
       baseurl="/api/payment/easebuzz/payment-details"
      
    }else{
      object.email=this.data.lastName;
    }
     
    let  url = environment.serverAPI3 + baseurl;
    return this.http.post<any>(`${url}`, object).subscribe(
  //  return this.http.post<any>('http://localhost:8080/payment/payment-details', paymentPayload).subscribe(
      res => {

        if(res.data!=false){
          
          const easebuzzCheckout = new EasebuzzCheckout(this.config['easeBuzzAccessKey'], this.config['easeBuzzEnvironment']);
          document.getElementById('ebz-checkout-btn').onclick = (e) => {
            const options = {
              access_key: res.data, // access key received via Initiate Payment
              onResponse: (response) => {
                console.log(response);
                if(response['status']=='success'){

                  let paymentInformation = {}

                  if(this.partialAmountEnabled==1){
                    paymentInformation = {
                      currency: this.config['currencyBase'],
                      Partial_Payment:'Yes',
                      Partial_Paid_Amount:this.finalAmount,
                      paymentType:response['card_type'],
                      easepayid:response['easepayid']
                    }
                  }else{
                    paymentInformation = {
                      currency: this.config['currencyBase'],
                      amount:response['amount'],
                      paymentType:response['card_type'],
                      easepayid:response['easepayid'],
                      
                    }
                  }
                 
              
                  this.additionData = JSON.stringify(paymentInformation)
              
                  this.paymentObject = {
                    salesPaymentTransaction: [{
                      txn_id: response['easepayid'],
                      txn_type: "customer",
                      isClose: true
                    }],
                    salesFlatOrderPayment: {
                      method: 'ease_buzz',
                      additionalInformation: this.additionData
              
                    },
                    paypalPaymentStatus: "Successful"
              
                  }
              
                   this.paymentStatus.emit(this.paymentObject);
                }else if(response['status']=="userCancelled"){
                  this.initiatePayment();
                }
                
              },
              theme: "#123456" // color hex
            }
            easebuzzCheckout.initiatePayment(options);
          };
        }
   
    }, error1 => {

      console.log(error1);
      

      })
    
    
  }



}
