import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhonePeService {

  constructor(private http: HttpClient,
    private userService: UserService,) { }

    url = environment.serverAPI3 + '/api/';

  post(request){
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){

      return this.http.post<Response[]>(`${this.url}/phonepe/payments`, request);
    }else{
      return this.http.post<Response[]>(`${this.url}/ig/phonepe/payments`, request);
    }
  
  }
}
