import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EcryptServiceService } from 'src/app/services/ecrypt-service.service';
import { environment } from 'src/environments/environment';
declare var bolt: any;

@Component({
  selector: 'calsoft-payu',
  templateUrl: './payu.component.html',
  styleUrls: ['./payu.component.scss']
})
export class PayuComponent {
  email: string;

  @Input() finalAmount: any;

  @Input() data: any;

  @Input() order: any;

  @Input() configData: any;

  @Input() partialAmountEnabled : any;

  @Input() contact: any;

  
  @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>();
  scriptLoaded: boolean=false;

  constructor(private http: HttpClient,
    private snackBar: MatSnackBar,
    public formBuilder: UntypedFormBuilder,
    private ecryptServiceService:EcryptServiceService,
    private renderer: Renderer2
    ) { }
  firstFormGroup: UntypedFormGroup;
  ngOnInit() {

    if (!this.scriptLoaded) {
      this.loadScript();
    }    
    let value=  this.email=localStorage.getItem('authenticaterUserEmail');
    if(value!=null){
     this.email=localStorage.getItem('authenticaterUserEmail');
    }else{
     this.email=this.data['lastName']
    }this.firstFormGroup = this.formBuilder.group({
      'email': ['',],
      'name': ['',],
      'phone': ['',],
      'productInfo': ['',],
      'amount': ['',],
      'surl': ['',],
      'furl': ['',],
      'key': ['',],
      'hash': ['',],
      'txnid': ['',],
      'service_provider': new UntypedFormControl('payu_paisa'),
      'customValue':['']
    });

  }

  loadScript() {
    const script = document.createElement('script');
    script.src = 'https://api.payu.in/js-sdks/bolt.min.js';
    script.async = true;
    script.onload = () => {
      this.scriptLoaded = true; 
            // Razorpay script has loaded successfully
      // You can now use Razorpay functionalities
      // Call a method in your service to set up Razorpay
    };
    script.onerror = (error) => {
      // Handle script loading error, if any
      console.error('Error loading Razorpay script:', error);
    };

    // Append the script to the document's head
    document.head.appendChild(script);
  }
  public payuform: any = {};
  launchBolt() {

    let  url = environment.serverAPI3 + "/api/payment/payu/payment-details";

    this.firstFormGroup.value.customValue=this.ecryptServiceService.encrypt(this.order);
    this.firstFormGroup.value.email=this.email,
    this.firstFormGroup.value.name= this.data.firstName,

    this.firstFormGroup.value.phone= this.data.telephone,

    this.firstFormGroup.value.productInfo= "Shopping",

    this.firstFormGroup.value.amount=this.finalAmount

    return this.http.post<any>(`${url}`, this.firstFormGroup.value).subscribe(
      res => {
        this.payuform.productinfo=res.productInfo;
      this.payuform.firstname=res.name;
      this.payuform.email=res.email;
      this.payuform.phone=res.phone;
      this.payuform.amount=res.amount;
      this.payuform.surl = res.sUrl;
      this.payuform.furl = res.fUrl;
      this.payuform.key = res.key;
      this.payuform.hash = res.hash;
      this.payuform.txnid = res.txnId;

      const data = { 
        key: this.payuform.key,
        hash: this.payuform.hash,
        txnid: this.payuform.txnid,
        amount:this.payuform.amount,
        firstname:  this.payuform.firstname,
        email:this.payuform.email,
        phone: this.payuform.phone,
        productinfo: this.payuform.productinfo,
        surl:'http://thirdparty.com/testresponse.php',
        furl:'http://thirdparty.com/testresponse.php',
        lastname: "soni",
        enforce_paymethod: "",
        display_lang: "English",
        drop_category: "creditcard|debitcard",
        pg: "",
        custom_note: "You will be charged an extra amount of Rs 100 on this transaction"
      };
      
     
   
      const handlers = {
        responseHandler: (BOLT: any) => {
          if (BOLT.response.txnStatus === 'SUCCESS') {
                      
            this.emitResponse(BOLT.response.mihpayid)
          }
          if (BOLT.response.txnStatus === 'FAILED') {
          
            this.snackBar.open('Payment failed. Please try again.', '×',
             { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
            this.enableScroll();
          }
          if (BOLT.response.txnStatus === 'CANCEL') {
            this.snackBar.open('Payment failed. Please try again.', '×',
             { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          
            this.enableScroll();
          }
        },
        catchException: (BOLT: any) => {
          this.snackBar.open('Payment failed. Please try again.', '×',
          { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
       
        
        }
      };               
      bolt.launch( data , handlers );
    }, error1 => {

      })


  }

  enableScroll() {
    this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  additionData: string;
  paymentObject: any;

  emitResponse(response){
    let paymentInformation = {}

    if(this.partialAmountEnabled==1){
      paymentInformation = {
        mihpayId: response,
      
        Partial_Paid_Amount:this.finalAmount,
        method: 'payu',
      }
    }else{
      paymentInformation = {
        mihpayId: response,
        method: 'payu',
  
      }
    }
   

    this.additionData = JSON.stringify(paymentInformation)

    this.paymentObject = {
      salesPaymentTransaction: [{
        txn_id: response['mihpayId'],
        txn_type: "customer",
        isClose: true
      }],
      salesFlatOrderPayment: {
        method: 'payu',
        additionalInformation: this.additionData

      },
      paypalPaymentStatus: "Successful"

    }

     this.paymentStatus.emit(this.paymentObject);
  }
}

