  <div class="padding-l60 padding-b10">
    <button (click)="checkout()"
     style="color:white" class="primary"
      mat-flat-button>PAY NOW</button>
  </div>

  <form #form ngNoForm id="nonseamless" method="post" name="redirect"
  [action]="ccAvenueUrl">
    <input type="hidden" id="encRequest" name="encRequest" 
    [value]="encRequestRes">
    <input type="hidden" name="access_code" id="access_code" 
    [value]="accessCode">
    <!-- Add other form inputs if needed -->
  
   <!-- <button mat-button type="submit">Submit</button>  -->
  </form>
  <!-- <button mat-button >Close</button> -->