<div *ngIf="customField13==0">
<div *ngIf="imageVisible" style="border: 1px dotted #ddd;
border-radius: 0px;padding: 15px 10px;" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <div fxLayout="column">

    <div fxLayout="row" fxLayoutGap="10px">

      <span fxLayoutAlign="start center" style="display: inline-block;">
        <img [src]="serverPath1+value" style="height: 50px; width: auto; display: inline-block;"
          onerror="this.src='assets/images/no-image.png';">
        {{value}}
      </span> 
        
      <div fxLayout="row" fxLayoutAlign="center center" class="center-content">
        <span>{{imagename}}</span>
      </div>
      
      
      

      <ng-container fxLayoutAlign="end center">
        <button (click)="logo()" mat-raised-button>Upload</button>
      </ng-container>

    </div>
  </div>
</div>

<div fxShow fxHide.lt-md>
  <div *ngIf="!imageVisible&&!imageDelete" style="border: 1px dotted #ddd;border-radius: 0px;padding: 15px 10px;"
    fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <calsoft-common-file-upload (fileUploadResult)=getResultNew($event) [type]="'gallery1'">
    </calsoft-common-file-upload>
    <button (click)="cancelUpload()" mat-raised-button>Cancel</button>
  </div>
</div>

<div fxShow fxHide.gt-sm>
  <div *ngIf="!imageVisible&&!imageDelete" style="border: 1px dotted #ddd;border-radius: 0px;padding: 15px 10px;"
    fxLayoutGap="10px">
    <calsoft-common-file-upload (fileUploadResult)=getResultNew($event) [type]="'gallery1'">
    </calsoft-common-file-upload>
    <button (click)="cancelUpload()" mat-raised-button>Cancel</button>
  </div>
</div>
</div>


<div *ngIf="customField13==1" fxLayout="column">

  <div class="editSets">
    <div class="title-head3" color="primary">
      <span>Image</span>
    </div>
    <div id="responsieTable" class="container" style="overflow-x:auto;">
      <table class="rwd-table">
        <tbody>
          <tr>
            <th>Image</th>
            <th>Action</th>
          </tr>
          <ng-container>
            <tr *ngFor="let images of imageList; let k = index">
              <td data-th="Image" style="width: 60%;">
                <img style="height: 65px;width: auto;" [src]="serverPath + images.imageName"
                  onerror="this.src='assets/images/no-image.png';" alt="">
              </td>
              <td data-th="Action">
                <button color="warn" mat-icon-button type="button" (click)="removeImageOption(k)">
                  <mat-icon>remove_circle_outline</mat-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>


</div>

<div *ngIf="customField13==1">
  <calsoft-common-file-upload    [imagename]="imagename" (fileUploadResult)="getResult($event)" [type]="'gallery'"></calsoft-common-file-upload>
</div>

<div *ngIf="customField13==1" class="gapbottom" fxLayoutAlign="end center">
  <button fxFlex="100" (click)="newForm()"  type="submit" style="color:white" mat-raised-button
  class="primary">Submit</button>
</div>