import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../field.interface';

@Component({
  selector: 'calsoft-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent {
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  ngOnInit() {}
  

  isRequired(fieldName: string): boolean {
    const control = this.group.get(fieldName);
    return control && control.hasError('required');
  }
  
}
