import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { EcryptServiceService } from 'src/app/services/ecrypt-service.service';
import { SalescartService } from 'src/app/services/salescart.service';

@Component({
  selector: 'calsoft-oppwa',
  templateUrl: './oppwa.component.html',
  styleUrls: ['./oppwa.component.scss']
})
export class OppwaComponent implements OnInit {
  url: string;

  constructor(
    private cartService:CartService,
    private ecryptServiceService:EcryptServiceService,
    private router: Router
    ) { }

  @Input() finalAmount: any;

  @Input() order: any;


  ngOnInit(): void {
    let value=window.location.origin;
    this.url=value +"/pay";

    this.payment();
  }



  payment() {

    let value={
      amount:this.finalAmount,
      customValue:this.ecryptServiceService.encrypt(this.order)
    }
      this.cartService.getPayment(value).subscribe(
        (response) => {


         let id =response['data']['id'];

         let url =response['data']['url'];

         this.addPaypalScript(id,url)


        },
        error => {
        }
      )
    }


  addPaypalScript(id,url) {

    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      let scriptUrl=url+"paymentWidgets.js?checkoutId="+id;
      //let scriptUrl='https://test.oppwa.com/v1/paymentWidgets.js?checkoutId='+id;
      scripttagElement.src = scriptUrl;
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }





}
