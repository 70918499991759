<form ngClass.gt-md="container" [formGroup]="firstFormGroup" autocomplete="off" *ngIf="Displaytype==1">
  <div [ngClass]="{'overlayProduct':approval==2 }">
    <div class="bg-color mat-elevation-z1">
      <div class="time-pad" fxLayoutAlign="space-between center">
        <span class="font-weight-500 font-22px">Ratings & Reviews</span>
      </div>
    </div>
    &nbsp;

    <div fxLayout="row" fxLayoutGap="10px">

      <div class="bg-color mat-elevation-z1" fxFlex.gt-md="30" fxHide fxShow.gt-sm>
        <div class="ptd15 borderb " fxLayoutAlign="start center">
          <span class="font18px padding-l15 ">What makes a good review
          </span>
        </div>
        <div class="padding-10">
          <div>
            <span>Have you used this product?</span>
          </div>
          <div>
            <span>Your review should be about your experience with the product.</span>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="padding-10">
          <div>
            <span>Why review a product?</span>
          </div>
          <div>
            <span>Your valuable feedback will help fellow shoppers decide!</span>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="padding-10">
          <div>
            <span>How to review a product?</span>
          </div>
          <div>
            <span>Your review should include facts. An honest opinion is always appreciated. If you have an issue with
              the
              product or service please contact us from the help centre.</span>
          </div>
        </div>

      </div>
      <div class="bg-color mat-elevation-z1" fxFlex="70" fxFlex.lt-md="100">
        <div class="borderb" fxLayout="column" fxLayoutGap="10px">
          <span class="padding-10 font18px padding-l15">
            Rate this product
          </span>
        </div>
        <div class="borderb">
          <div formArrayName="ratingOptionVote"
            *ngFor="let ratingOptionVoteArray of firstFormGroup.get('ratingOptionVote')['controls']; let i = index">
            <div [formGroupName]="i">

              <div  fxLayout="row" fxLayoutAlign="start center">
                <calsoft-Rating fxFlex.gt-sm="70" class="padding-r15" (onRatingChange)="getRatingData($event)"
                  [type]="'checkout'" [id]="id" [ratingsCount]="firstFormGroup.value.ratingOptionVote[0].value"
                  [ratingsValue]="10" [direction]="'row'">
                </calsoft-Rating>

                <mat-error *ngIf="ratingOptionVoteArray.controls['value'].errors?.required && requiredRatingVisible">
                  Required</mat-error>
                <!-- <mat-error fxFlex="30" *ngIf="firstFormGroup.controls.ratingOptionVote['controls'][0].value.errors?.required ">Rating is required
        </mat-error>   -->
              </div>
            </div>
          </div>

        </div>
        <div>

          <div class="borderb">
            <div class="padding-10">
              <span class="font18px ">Review this product
              </span>
            </div>
          </div>

          <div class="padding-10">
            <br>
            <div fxLayout="column" fxLayoutGap="10px">

              <mat-form-field formGroupName="reviewDetail" fxFlex="25" appearance="outline">
                <mat-label>Title Of Review</mat-label>
                <textarea matTextareaAutosize matAutosizeMinRows=2 matAutosizeMaxRows=5 trim="blur"   name="new-label"  [autocomplete]="'off'" [readonly]="approval=='2' || id>0" formControlName="title" matInput required></textarea>
                <mat-error *ngFor="let validation of validation.title">
                  <mat-error class="error-message"
                    *ngIf="firstFormGroup.controls.reviewDetail['controls']['title'].hasError(validation.type) && (firstFormGroup.controls.reviewDetail['controls']['title'].dirty || firstFormGroup.controls.reviewDetail['controls']['title'].touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>

              </mat-form-field>
              <div class="outof centered title" fxLayout="row" fxLayoutAlign="center center">
                <span>
                  {{approval=='1'?"Review Approved":approval=='2'?"Waiting For Approval":approval=='3'?"Approval Rejected":""}}
                </span>
              </div>

              <mat-form-field formGroupName="reviewDetail" fxFlex="25" appearance="outline">
                <mat-label>Detail Of Review </mat-label>
                <textarea matTextareaAutosize matAutosizeMinRows=2 matAutosizeMaxRows=5 trim="blur"   name="new-label"  [autocomplete]="'off'" [readonly]="approval=='2' || id>0" formControlName="detail" matInput required></textarea>
                <mat-error *ngFor="let validation of validation.detail">
                  <mat-error class="error-message"
                    *ngIf="firstFormGroup.controls.reviewDetail['controls']['detail'].hasError(validation.type) && (firstFormGroup.controls.reviewDetail['controls']['detail'].dirty || firstFormGroup.controls.reviewDetail['controls']['detail'].touched)">
                    {{validation.message}}
                  </mat-error>
                </mat-error>
              </mat-form-field>

              <div fxLayout="row" fxLayoutGap="10px">
                <button color="warn" (click)="cancel()" mat-stroked-button>
                  Cancel
                </button>
                <button [disabled]="approval=='2' || id>0" (click)="createReview()" color="primary" mat-stroked-button>
                  {{text}}
                  <mat-icon *ngIf="text !='SUBMIT' ">
                    <mat-spinner color="primary" diameter="20">
                    </mat-spinner>
                  </mat-icon>
                </button>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</form>
<form *ngIf="Displaytype==2" [formGroup]="firstFormGroup" autocomplete="off" class="rating-popup">
  <div class="popup-content">
    <div class="popup-header">
      <span class="popup-title">Your Review</span>
      <button mat-icon-button class="close-button" (click)="closePopup()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="popup-body">
      <div class="section-title">
        Rate this Course
      </div>

      <div class="rating-section" formArrayName="ratingOptionVote">
        <div *ngFor="let ratingOptionVoteArray of firstFormGroup.get('ratingOptionVote')['controls']; let i = index" [formGroupName]="i" class="rating-item">
          <calsoft-Rating (onRatingChange)="getRatingData($event)" [type]="'checkout'" [id]="id" fxFlex="100"
            [ratingsCount]="firstFormGroup.value.ratingOptionVote[i].value" [ratingsValue]="10" [direction]="'row'">
          </calsoft-Rating>
          <mat-error *ngIf="ratingOptionVoteArray.controls['value'].errors?.required && requiredRatingVisible">
            Required
          </mat-error>
        </div>
      </div>

      <div class="section-title">
        Review this Course
      </div>

      <div class="review-section">
        <div class="review-item">
          <mat-form-field formGroupName="reviewDetail" appearance="outline" fxFlex="100">
            <mat-label>Title Of Review</mat-label>
            <textarea matTextareaAutosize matAutosizeMinRows=2 matAutosizeMaxRows=5 trim="blur" name="new-label"
              [autocomplete]="'off'" [readonly]="approval=='2' || id>0" formControlName="title" matInput required></textarea>
            <mat-error *ngFor="let validation of validation.title">
              <mat-error class="error-message"
                *ngIf="firstFormGroup.controls.reviewDetail['controls']['title'].hasError(validation.type) && (firstFormGroup.controls.reviewDetail['controls']['title'].dirty || firstFormGroup.controls.reviewDetail['controls']['title'].touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="review-item">
          <mat-form-field formGroupName="reviewDetail" appearance="outline" fxFlex="100">
            <mat-label>Detail Of Review</mat-label>
            <textarea matTextareaAutosize matAutosizeMinRows=2 matAutosizeMaxRows=5 trim="blur" name="new-label"
              [autocomplete]="'off'" [readonly]="approval=='2' || id>0" formControlName="detail" matInput required></textarea>
            <mat-error *ngFor="let validation of validation.detail">
              <mat-error class="error-message"
                *ngIf="firstFormGroup.controls.reviewDetail['controls']['detail'].hasError(validation.type) && (firstFormGroup.controls.reviewDetail['controls']['detail'].dirty || firstFormGroup.controls.reviewDetail['controls']['detail'].touched)">
                {{validation.message}}
              </mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="actions">
        <button [disabled]="approval=='2' || id>0" (click)="createReview()" color="primary" mat-stroked-button>
          {{text}}
          <mat-icon *ngIf="text != 'SUBMIT'">
            <mat-spinner color="primary" diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</form>



