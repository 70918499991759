import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'calsoft-redsys',
  templateUrl: './redsys.component.html',
  styleUrls: ['./redsys.component.scss']
})
export class RedsysComponent implements OnInit {
  addScript: any;
  paypalLoad: boolean;

  constructor() { }

  ngOnInit(): void {
  }


  paypalInit(): void {
    if (!this.addScript) {
      this.addPaypalScript().then(() => {

      })
    }
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV2.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }
}
