import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Constant } from 'src/app/constants/constant';
import { EcryptServiceService } from 'src/app/services/ecrypt-service.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-pay-umoney',
  templateUrl: './pay-umoney.component.html',
  styleUrls: ['./pay-umoney.component.scss']
})
export class PayUMoneyComponent implements OnInit {

  @Input() finalAmount: any;

  @Input() data: any;

  @Input() configData: any;

  @Input() order: any;

  @Input() partialAmountEnabled : any;

  @Input() contact: any;

  @Output() paymentStatus: EventEmitter<any> = new EventEmitter<any>();

  public payuform: any = {};
  disablePaymentButton: boolean = true;
  firstFormGroup: UntypedFormGroup;
  email: string;
  constructor(private http: HttpClient,
    public formBuilder: UntypedFormBuilder,
    private ecryptServiceService:EcryptServiceService,
    private userService:UserService) { }

  confirmPayment() {
    const paymentPayload = {
      email: this.data['email'],
      name: this.data.firstName,
      phone: this.data.telephone,
      productInfo: "Shopping",
      amount: this.finalAmount

    }

    this.firstFormGroup.value.customValue=this.ecryptServiceService.encrypt(this.order);
    this.firstFormGroup.value.email=this.email,
    this.firstFormGroup.value.name= this.data.firstName,

    this.firstFormGroup.value.phone= this.data.telephone,

    this.firstFormGroup.value.productInfo= paymentPayload.productInfo,

    this.firstFormGroup.value.amount=this.finalAmount

   let  url = environment.serverAPI3 + "/api/payment/payu/payment-details";
    return this.http.post<any>(`${url}`, this.firstFormGroup.value).subscribe(
  //  return this.http.post<any>('http://localhost:8080/payment/payment-details', paymentPayload).subscribe(
      data => {
      
      
        let  url1 = environment.serverAPI3+"/api/payment/payu/payment-response";

      const url = this.configData['payUUrl'];

      
      const paymentForm = document.createElement('form');
      paymentForm.action = url;
      paymentForm.method = 'POST';
      data.sUrl=url1;
      data.fUrl=url1;
      this.payuform.productinfo=data.productInfo;
      this.payuform.firstname=data.name;
      this.payuform.email=data.email;
      this.payuform.phone=data.phone;
      this.payuform.amount=data.amount;
      this.payuform.sUrl =   data.sUrl;
      this.payuform.fUrl =  data.fUrl;
      this.payuform.key = data.key;
      this.payuform.hash = data.hash;
      this.payuform.txnid = data.txnId;
      Object.keys(this.payuform).forEach(key => {
        const inputField = document.createElement('input');
        inputField.type = 'hidden';
        inputField.name = key;
        inputField.value = this.payuform[key];
        paymentForm.appendChild(inputField);
      });
  

      this.payuform.service_provider="payu_paisa"

      const paymentWindow = window.open('', 'payment_popup', 'width=900,height=500');
     // paymentWindow.document.domain = 'localhost';
      paymentForm.target = 'payment_popup';
    paymentWindow.document.body.appendChild(paymentForm);
    paymentForm.submit();
  
    const checkWindowClosed = setInterval(() => {
      if (paymentWindow.closed) {
        clearInterval(checkWindowClosed);
        console.log('Payment window closed');

        let object={
          "txnId":data.txnId
        }

        this.closePopUp(object)

       }
          
      
    }, 1000);

  
 

  // let url="https://test.payu.in/_payment"
  //     let paymentString = `
  //     <html>
  //       <body>
  //         <form action="${url}" method="post" id="payu_form">
  //           <input type="hidden" name="firstname" value="${this.payuform.firstname}"/>
  //           <input type="hidden" name="email" value="${this.payuform.email}"/>
  //           <input type="hidden" name="phone" value="${this.payuform.phone}"/>
  //           <input type="hidden" name="surl" value="http://localhost:8086/ecom-gateway/ecom-svc-sales-1.0-SNAPSHOT/api/payment/payu/payment-response"/>
  //           <input type="hidden" name="curl" value="${data.fUrl}"/>
  //           <input type="hidden" name="furl" value="${data.fUrl}"/>
  //           <input type="hidden" name="key" value="${this.payuform.key}"/>
  //           <input type="hidden" name="hash" value="${this.payuform.hash}"/>
  //           <input type="hidden" name="txnid" value="${this.payuform.txnid}"/>
  //           <input type="hidden" name="productinfo" value="${data.productInfo}"/>
  //           <input type="hidden" name="amount" value="${data.amount}"/>
  //           <input type="hidden" name="service_provider" value="${this.payuform.service_provider}"/>
  //           <button type="submit" value="submit" #submitBtn></button>
  //         </form>
  //         <script type="text/javascript">document.getElementById("payu_form").submit();</script>
  //       </body>
  //     </html>`;

  //     const winUrl = URL.createObjectURL(
  //       new Blob([paymentString], { type: "text/html" })
  //     );
      
  //     const paymentWindow = window.open(
  //       winUrl,
  //       "payment_popup",
  //       "width=900,height=500"
  //     );
      
  //     if (paymentWindow) {
  //       this.disablePaymentButton = false;
  //     } else {
  //       // Handle error opening popup
  //       console.error("Failed to open payment popup window");
  //     };

  //     paymentWindow.addEventListener('beforeunload', () => {
  //       // code to run when the popup is closed
  //       console.log('Popup closed!');
  //     });
    
    }, error1 => {

      })
  }

  closePopUp(value){
    let  url = environment.serverAPI3 + "/api/payment/payu/transaction";
    return this.http.post<any>(`${url}`, value).subscribe(
  //  return this.http.post<any>('http://localhost:8080/payment/payment-details', paymentPayload).subscribe(
      data => {

      console.log(data.data);

      data=data.data;

      console.log(data);

      if(data.paymentStatus=="Success"){
        this.emitResponse(data)
        
      }
  
    
    }, error1 => {

      })
  }

  additionData: string;
  paymentObject: any;
  ngOnInit() {

    console.log(localStorage.getItem('authenticaterUserEmail'));
    
    this.email=this.data['email']==undefined?this.data['lastName']:this.data['email'];
    this.payuform.service_provider="payu_paisa"
    this.firstFormGroup = this.formBuilder.group({
      'email': ['',],
      'name': ['',],
      'phone': ['',],
      'productInfo': ['',],
      'amount': ['',],
      'surl': ['',],
      'furl': ['',],
      'key': ['',],
      'hash': ['',],
      'txnid': ['',],
      'service_provider': new UntypedFormControl('payu_paisa'),
      'customValue':['']
    });
  }


  emitResponse(response){
    let paymentInformation = {}

    if(this.partialAmountEnabled==1){
      paymentInformation = {
        mihpayId: response['mihpayId'],
      
        Partial_Paid_Amount:this.finalAmount,
        method: 'payu',
      }
    }else{
      paymentInformation = {
        mihpayId: response['mihpayId'],
        method: 'payu',
  
      }
    }
   

    this.additionData = JSON.stringify(paymentInformation)

    this.paymentObject = {
      salesPaymentTransaction: [{
        txn_id: response['mihpayId'],
        txn_type: "customer",
        isClose: true
      }],
      salesFlatOrderPayment: {
        method: 'payu',
        additionalInformation: this.additionData

      },
      paypalPaymentStatus: "Successful"

    }

     this.paymentStatus.emit(this.paymentObject);
  }


}
