import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductListiingService {

  url = environment.serverAPI4+ "/api/ig/productlisting";

  url1 = environment.serverAPI4 + "/api/productlisting"; 

  constructor(private http: HttpClient) {
  }


  submitProductListing(supplier:any): Observable<Response[]> {
    return this.http.post<Response[]>(`${this.url}`, supplier);
  }
  
}
