import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Constant } from '../constants/constant';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CcAvenueService {


  url = environment.serverAPI3 + '/api/payment';
  encRequestRes: any;

  constructor(private http: HttpClient,
    private userService: UserService,
    ) { }

  encryptdata(request){
    let url = `${this.url}`;
    let data = {
    request : request
    }
    return this.http.get(url,{params:data})
  }

  post(request){
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){

      return this.http.post<Response[]>(`${this.url}/ccAvenue/encryptFormData`, request);
    }else{
      return this.http.post<Response[]>(`${this.url}/ig/ccAvenue/encryptFormData`, request);
    }
  
  }


}
