import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalsoftService } from 'src/app/services/Calsoft.service';
import { CartService } from 'src/app/services/cart.service';
import { UserService } from 'src/app/services/user.service';
import { CommonSignInComponent } from 'src/app/shared/CommonSignIn/CommonSignIn.component';
import { Location } from '@angular/common';
@Component({
  selector: 'calsoft-mobile-header-fixed-white',
  templateUrl: './mobile-header-fixed-white.component.html',
  styleUrls: ['./mobile-header-fixed-white.component.scss']
})
export class MobileHeaderFixedWhiteComponent implements OnInit {

  @Input() url: any;
  @Input() userLoggedIn: any;
  urlEnabled: boolean=false;
  @Input() data: any;
  constructor(
    private router: Router,
    public cartServices: CartService,
    private dialog: MatDialog,
    private userService: UserService,

    private location: Location,
    public calsoftService: CalsoftService,) { }

  ngOnInit(): void {
    this.userLogged();

   // console.log(this.router.url);
    
    if(this.router.url=="/cart"){
      this.urlEnabled=false;
    }else{
      this.urlEnabled=true;
    }

  }
  page1: any;
  public toggleSidebar() {
    this.calsoftService.sidenavOpen = !this.calsoftService.sidenavOpen;
  }

  cart() {
    let basicAuthHeaderString = this.userService.getAuthenticatedToken();
    let username = this.userService.getAuthenticatedUser();

    if (basicAuthHeaderString && username) {
      this.router.navigate(['/cart'])

    }
    else {
      this.page1 = "cart";
      this.loginModel();

      // this.router.navigate(['/cart'])

    }

  }


  loginModel() {
    if (window.innerWidth > 768) {

      let dialogRef = this.dialog.open(CommonSignInComponent, {
        width: '790px',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });

    }
    else {
      let dialogRef = this.dialog.open(CommonSignInComponent, {
        minWidth: '100vw', height: '100vh',
        data: { page: this.page1, }
      });

      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();


      });
    }

  }
  back() {

    if(  this.router.url.includes('/order/')){
      this.router.navigate(['/orders'])
    }else{
      this.location.back();
    }
  
 }


 SignIn() {
  // this.router.navigate(['/sign-in']);
  this.loginModel();
}


userLogged() {

  this.router.routeReuseStrategy.shouldReuseRoute = function () {
    return false;
  };

  let basicAuthHeaderString = this.userService.getAuthenticatedToken();
  let username = this.userService.getAuthenticatedUser();

  if (basicAuthHeaderString && username) {

    this.userLoggedIn = true;
    this.cartServices.getCount();

  }
  else {

    this.userLoggedIn = false;
  }
}



}
