import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomHandlerService } from './dom-handler.service';




@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  menuId: any=0;

  menuList: any=0;
  expandedItems: any=[];

  constructor(private location:Location, private router:Router, private domHandlerService: DomHandlerService){ } 




  public expandActiveSubMenu(menu:Array<any>){
      let url = this.location.path();
      let routerLink = decodeURIComponent(url);
      let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
      if(activeMenuItem[0]){
          let menuItem = activeMenuItem[0];
          while (menuItem.parentId != 0){  
              let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
              menuItem = parentMenuItem;
              this.toggleMenuItem(menuItem.id,menuItem.parentId,menu);
          }
      }
  }

  public toggleMenuItem(menuId,parentId,menu){
      let menuItem = this.domHandlerService.winDocument.getElementById('menu-item-'+menuId);
      let subMenu = this.domHandlerService.winDocument.getElementById('sub-menu-'+menuId);  
      if(subMenu){
          if(subMenu.classList.contains('show')){
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');


              if(parentId>2){
                menu.forEach(element => {
                  let index=this.expandedItems.findIndex(item => item === element.categoryId);
                  if(index>=0){
                    this.expandedItems.splice(index, 1);
                    if(element.subcategories.length>0&&this.expandedItems.length>1){
                      this.recursice(element.subcategories);
                    }
                  }
                });
              }
              let index=this.expandedItems.findIndex(item => item === menuId);
                  if(index>=0){
                    this.expandedItems.splice(index, 1);
                  }
             
              
          }
          else{
              subMenu.classList.add('show');
              menuItem.classList.add('expanded');


              if(parentId==2){
                this.expandedItems=[];
                this.expandedItems.push(menuId);
              }else  if(parentId>2){
              
                this.expandedItems.push(menuId);

              }
             
              this.menuId=menuId;
          }      
      }
  }
  recursice(subcategories: any) {
    subcategories.forEach(element => {
      let index=this.expandedItems.findIndex(item => item === element.categoryId);
      if(index>=0){
        this.expandedItems.splice(index, 1);
        if(element.subcategories.length>0&&this.expandedItems.length>1){
          this.recursice(element.subcategories);
        }
      }
    });
  }

  public closeOtherSubMenus(menu:Array<any>, menuId){
   
      let currentMenuItem = menu.filter(item => item.categoryId == menuId)[0];
      menu.forEach(item => {
          if((item.categoryId != menuId && item.parentId == currentMenuItem.parentId) || (currentMenuItem.parentId == 0 && item.categoryId != menuId) ){
              let subMenu = this.domHandlerService.winDocument.getElementById('sub-menu-'+item.categoryId);
              let menuItem = this.domHandlerService.winDocument.getElementById('menu-item-'+item.categoryId);
              if(subMenu){
                  if(subMenu.classList.contains('show')){
                      subMenu.classList.remove('show');
                      menuItem.classList.remove('expanded');
                  }              
              } 
          }
      });
  }



}
